/// <reference path="shared/shared_constants.tsx" />
'use strict';
const config = require('Config');
const build_version_config = require('build_version_config');

const base_url = config.endpoint;
const api_version = config.api_version;
const api_versions = config.api_versions;
//export const page_size = 100;
// Base Url
export const base_end_points = `${base_url}${api_version}`;
export const base_end_points_v2 = `${base_url}${api_versions.v2}`;

export const build_version = `${build_version_config.environment.environment} ${build_version_config.environment.version}`;
export const copyright_info = `${config.environment.copyright}`;
export const show_app_version = config.environment.show_version;

export const task_icon_config = config.task_icon_config;
export const analytics_icon_config = config.analytics_icon_config;
export const sso_config_data = {
    sso_config: config.sso_config,
    emr_win_name: 'EMR_LOGIN_WIN',
    analytics_win_name: 'ANALYTICS_LOGIN_WIN',
    sso_login_token_key: 'wpt_sso_token',
    sso_setup_token_key: 'token'
};
export const sso_url = {
    webpt_emr_url: config.sso_config.webpt_emr_url,
    webpt_emr_authorize: config.sso_config.webpt_emr_authorize
}

export const billing_to_patient_emr_url = config.sso_config.billing_to_patient_emr_url;

export const upload_835 = config.upload_835;

export const url_rewrite = {
    error_message: 'No Record Found!',
    navigate_to: '/patient_search'
}
export const maximum_value = {
    integer: 2147483647,
    big_integer: 9223372036854775807
}
// API Call end points
export const end_points = {
    base_app_url: "",
    base_emaded_url: "/R2",
    login_urls: {
        token: '/token',
        authenticate: '/authentication',
        sso_authenticate: '/sso/sso_authentication',
        sso_verification_post: '/sso/validate_post',
        keep_alive: '/sso/keep_alive',
        token_verify_post: '/sso/token_verify_post',
        sso_login_redirect: '/sso/redirect',
        sso_login_reset: '/sso/reset_login',
        sso_logout: '/sso/logout',
    },
    oauth_urls:{
        exchange: '/exchange',
        logout: '/logout',
        sso_login: '/sso/sso_login',
        sso_identity_application: '/sso/sso_identity_application',
    },
    patient_urls: {
        patients: '/patients',
        quick_search: '/patients/quick_search',
        header: '/header',
        header_case: '/case',
        search: '/search',
        medicare_caps: '/medicare_caps',
        account_notes: '/account_notes',
        task: "/tasks",
        note_codes: "/note_codes",
        get_patient_form_list: "/get_patient_form_list",
        notes: '/notes',
        cases: '/cases',
        account_rollup: '/account_rollup',
        search_note_code: '/account_notes/search_note_code',
        quick_search_note_code: '/account_notes/quick_search_note_code',
        merge_accounts:  '/patients/merge_accounts',
    },
    note_urls: {
        print_output_report: "/print_patient_output_report",
        scanned_documents: '/notes/scanned_document',
        print_scanned_documents: '/print_scanned_documents',
        open_PDF_by_guid: '/open_pdf_by_guid',
        open_scanned_document: '/open_scanned_document'
    },

    company_urls: {
        company_search: '/company/quick_search',
		company_lookup: '/company/lookup',
		get_company_dto: '/company/get_company_dto/{company_id}',
        company: '/company',
        company_full_detail: '/full_details',
        company_reference: '/refrences',
        user_companies: '/company/user',
        user_companies_by_status: '/company/user/charge_status/company',
        user_companies_by_status_audit: '/company/user/charge_status_audit/company',
        parent_companies: '/parent_companies',
        check_company_ff: '/company/check_company_ff',
        check_allow_override_format_for_demand_claims: '/company/check_allow_override_format_for_demand_claims',
        user_companies_without_user_type_filter: '/company/user/get_user_companies_without_user_type_filter',
        get_user_companies_by_user_type: '/company/user/get_user_companies_by_user_type',
        clearinghouse_domains: '/clearinghouse_domains',
        additional_settings: '/company/additional_settings',
        place_of_service: '/locations/get_location'
    },
    error_logging_url: '/log_error',
    user_urls: {
        base: 'user',
        permissions: '/user/permissions',
        logout: '/logout',
        refresh_token: '/refresh_token',
        forgot_password: '/forgot_password',
        reset_password: '/reset_password',
        forgot_password_token_validation: '/user_forgot_password_token_validation',
        user_details: '/user/',
        ip_registration: '/register_ip',
        change_password: '/change_password',
        r2_url: '/r2_url',
        send_ip_enabled_email: '/send_ip_enabled_email',
        has_default_company_access: '/has_default_company_permission',
        master_data: "/master/data",
        master_data_by_type: '/master/data_by_type',
        master_data_control: '/master/control/',
        master_sys_setting_by_key: '/master/sys_setting_by_key',
        user_type_advance_search: '/constant/users/get_search_user_type',
        user_type_quick_search: '/constant/users/quick_search_user_type',
        user_role_advance_search: '/constant/users/get_search_roles',
        user_role_quick_search: '/constant/users/quick_search_roles',
        admin_constant_company_detail: 'admin_constant_company_detail',
        user_companies: 'user_companies',
        get_user_type_for_roles: 'get_user_type_for_roles',
        service_type: 'service_type',
        emr_permissionset: '/user/get_sso_user_emr_permissionset'
    },
    shared_urls: {
        add: "/add",
        remove: "/remove"
    },
    payment: {
        unassigned_payment: {
            search_provider: '/provider_config/billing_provider',
            search_place: '/locations/place_of_service',
            get_insurance: '/insurance',
            save_payment: '/payments/credits',
            addPayment: '/payments/addCredits',
            updatePayment: '/payments/updateCredits',
            deletePayment: '/payments/deleteCredits',
            quick_search_location: '/locations/quick_search_place_of_service',
            quick_search_provider: '/provider_config/quick_search_billing_and_treating_provider',
            search_billing_and_treating_provider: '/provider_config/billing_and_treating_provider'
        },
        batch_payements: {
            search: '/batch_payments/search',
            references: '/batch_payments/references',
            save: '/batch_payments',
        },
        edit_payments: {
            search: '/payments/payment_edit',
            delete: '/payments/delete_payment_adjustment',
            update: '/payments/payment_edit_update'
        },
        bulk_payment_updates: {
            master_data_by_company: '/bulk_payment_updates/master_by_company',
            search: '/bulk_payment_updates/search',
            update: '/bulk_payment_updates/update',
        },
        relase_835_details: {
            release835DetailCard: '/payments/release835DetailCard',
            get_release_835_eob_patient_claim_details: '/payments/get_release_835_eob_patient_claim_details',
            validationEob: '/payments/validation/eob',
            get_unassigned_insurance_by_inbound_id: '/payments/get_unassigned_insurance_by_inbound_id',
            e835_add_inbound_id_to_payers_config: '/payments/e835_add_inbound_id_to_payers_config',
        },
        release_835_save_editable_fields: {
            save_eob_835_editable_fields: '/payments/save_eob_835_editable_fields'
        },
        upload_835_file: {
            upload: '/payments/upload_835'
        },
    },
    payment_urls: {
        payments: '/payments',
        credits: '/credits'
    },
    subscriber_url: {
        subscriber: "/subscriber",
        eligibility: "/eligibility",
        doctors_orders_visit: "/doctors_orders_visit",
        subscriber_info: "/subscriber_info",
        subscriber_details: "/subscriber_details",
        doctors_orders: "/doctors_orders",
        preauth: "/preauth",
        insurance: {
            insurance: "/insurance",
            search_insurance: "/search",
            search_insurance_sec_ter: "/search_st",
            quick_insurance: "/code/quick_search",
            quick_insurance_sec_ter: "/code/quick_search_st",
            quick_insurance_by_company: "/insurance/quick_search_insruance_by_company",
            search_insurance_by_company: "/insurance/search_insruance_by_company",
        }
    },
    barcode: {
        barcode: "/barcode",
        gernerate_barcodes: "/gernerate_barcodes"
    },
    case_url: {
        case: "/cases",
        new_preauth: "/new_preauth",
        foto_episodes: "/foto_episodes",
        retrive_foto_episodes: "/retrive_foto_episodes",
        foto_reports: "/foto_reports",
        retrive_foto_reports: "/retrive_foto_reports",
        print_verification_forms: "/open_print_pdf",
        case_summary: "/case_summary"
    },
    provider_type_url: {
        provider_code: "/providers/next_provider_code",
        primary_speciality: "/providers/specialty"
    },
    provider_config: {
        provider_config: "/provider_config",
        referring_physician_quick_search: "/quick_search_referring_phy",
        referring_physician_advance_search: "/search_referring_physician",

        referring_source_quick_search: "/quick_search_referring_source",
        referring_source_advance_search: "/search_referring_source",

        search_provider: '/provider_config/billing_provider',
        search_place: '/locations/place_of_service',
        search_company_pos: '/locations/place_of_service_by_company',
        quick_search_company_pos: '/locations/quick_search_place_of_service_by_company',
        quick_search_location: '/locations/quick_search_place_of_service',
        quick_search_provider: '/provider_config/quick_search_billing_provider',
        quick_search_treating_provider: '/provider_config/quick_search_treating_provider',

        descipline: '/provider_config/get_discipline',
        search_all_provider: '/provider_config/provider_search',
        quick_search_all_provider: '/provider_config/quick_search_provider',
        search_company_provider: '/provider_config/provider_company_search',
        quick_search_company_provider: '/provider_config/quick_search_company_provider',
    },
    referring_source: {
        referring_source: "/referral_source",
        referral_type: "/referral_type",
        referring_source_quick_search: "/quick_search_referring_source",
        referring_source_advance_search: "/search_referring_source",
        search_referring_source_type: "/search_referring_source_type",
        quick_search_referring_source_type: "/quick_search_referring_source_type",
        search_special_program: "/get_special_program",
        quick_special_program: "/quick_search_special_program",
        add_reffering_source: "/add_referring_source"
    },
    fax: {
        fax: '/fax',
        get_fax_queue_data: '/get_fax_queue_data',
        send_fax_queue: '/send_fax_queue'
    },
    diagnosis: {
        diagnosis: '/diagnosis',
        diagnosis_quick_search: '/quick_search_diagnosis',
        diagnosis_search: '/search_diagnosis',
        search_by_page: '/search_by_page',
        add_update_case_diagnosis_code: '/add_case_dx_code'
    },
    referring_physician: {
        get_medical_group_list: '/provider_config/get_medical_group',
        get_primary_specility_list: '/provider_config/get_referring_physician_speciality',
        add_referring_physician: '/provider_config/add_referring_physician',
    },
    insurance: {
        insurance: '/insurance',
        contract: '/insurance/contract',
        type: '/insurance/type',
        add_primary_insurance: '/insurance/add_ins_code',
        quick_search_class: '/insurance_class/quick_search',
        search_class: '/insurance_class/search',
        quick_search_payer: '/insurance_payer/quick_search',
        search_payer: '/insurance_payer/search',
        insurance_type_codes: '/insurance/ins_type_code_list',
        quick_search_class_by_company: '/insurance_class/quick_search_class_by_company',
        search_class_by_company: '/insurance_class/search_class_by_company',
        insurance_class_prov_cred: '/insurance/insurance_classes/provider_credentialing',
        has_active_providers: (insurance_class_id) => `/insurance_class/${insurance_class_id}/has_providers`
    },
    document: {
        document: "/document",
        open_pdf: "/open_pdf"
    },
    print_receipt: {
        payment: "/payments",
        get_print_reciept: "/receipts",
        get_company_logo: "/company/companylogo",
        print_patient_logo: "/company/print_patient_logo"
    },
    procedure_code: {
        procedure_code: '/procedure_code',
        procedure_codes: '/procedure_codes',
        lookup_search: '/lookup_search',
        quick_search: '/quick_search',
        procedure_code_modifiers: '/procedure_code_modifiers',
        lookup_search_by_company: '/lookup_search_by_company',
        quick_search_by_company: '/quick_search_by_company',
        search_proc_code: '/procedure_codes/search_proc_code',
        complete_proc_code: '/procedure_codes/complete_proc_code',
        company_modifiers: '/company_modifiers',
        multiple_fee_schedules: '/multiple_fee_schedules'
    },
    charge_url: {
        charge_case_list: '/charge_case_list',
        pre_authorization_list: '/pre_authorization_list',
        charge: '/charge',
        edit_charge: '/charge/edit',
        review_charge_summary: '/charge/review_summary',
        release_charge: '/charge/release',
        release_audit_charge: '/charge/release_audit_charge',
        release_audit_charges_handler: '/charge/release_audit_charges_handler',
        charge_review_details: '/charge/review_details',
        pending_reason: '/charge/pending_reasons',
        updated_charges: '/charge/updated_charges',
        deactivate_charge_audit: '/charge/deactivate_charge_audit',
        charge_pre_audit_summary: '/charge/charge_pre_audit_summary',
        charge_audit_details: '/charge/charge_audit_details',
        deactivate_bulk_charge_audit: '/charge/deactivate_bulk_charge_audit',
        charge_edit_rules: '/charge/charge_edit_rules',
        release_charge_detail: '/charge/release_detail'
    },
    procedure_code_replacement_url: {
        payer: '/procedure_code_replacement/payer',
        insurance_class: '/procedure_code_replacement/insurance_class',
        criteria: '/procedure_code_replacement/criteria',
        get_procedure_code_replacement: '/procedure_code_replacement/get_procedure_code_replacement',
        validate: '/procedure_code_replacement/validate',
        save: '/procedure_code_replacement/save'
    },
    provider: {
        provider: '/providers',
        provider_billing: '/billing',
        provider_claims: '/claim_hold',
        provider_pending_reason: '/pending_reason',
        get_payer_group_by_company: '/constant/payer_group/get_payer_group_by_company',
        get_default_payer_group_by_company: '/constant/payer_group/get_default_payer_group_by_company'
    },
    payment_code_url: {
        get_payment_codes: '/payment_code/get_payment_code',
        quick_search: '/payment_code/quick_search'
    },
    adjustment_code_url: {
        get_adjustment_codes: '/adjustment_code/get_adjustment_code',
        quick_search: '/adjustment_code/quick_search'
    },
    claim_indicator: {
        get_patient_insurance: '/insurance/get_patient_insurances',
        get_charges: '/charge/claim_indicator_charge_details',
        save_charge: '/charge/claim_indicator_save_charge',
        get_payer_group: '/constant/payer_group/insurance_code',
        get_condition_code: '/charge/claim_indicator_condition_codes'

        // get_patient_insurance: '/claim_indicator/insurance/get_patient_insurances'
    },
    open_claims: {
        search_data: '/payments/open_claims',
        formatter_billing_register_rpt: '/payments/formatter_billing_register_rpt',
        close_open_claims: '/payments/close_open_claims'
    },
    scheduled_month_end_urls: {
        search_data: '/scheduled_month_end/search',
        cancel_job: '/scheduled_month_end/cancel',
        initiate_job: '/scheduled_month_end/initiate',
        add_new_job: '/scheduled_month_end'
    },
    patient_letter: {
        patient_letters: "/patient_letters",
        patient_letters_cases: "/patient_letters/cases",
        patient_letters_preview: "/patient_letters/preview",
        search_patient_letter: "/patient_letters/search",
        get_patient_letter_variables: "/patient_letters/variables",
        save_patient_letter: "/patient_letters/save",
        get_patient_letter_edit: "/patient_letters/edit"
    },
    error_configuration_835_urls: {
		"search": "/constant/payers/get_835_payer_exclusions",
		"save": "/constant/payers/save_835_configuration"
    },
    batch_tasks_urls: {
        batch_tasks: '/batch_tasks',
        batch_tasks_programs: '/batch_tasks/programs',
        logs: '/batch_tasks/logs',
    },
    expected_rate_urls: {
        get_billing_tax_number: '/expected_rate/billing_tax_number',
        expected_rate_fee_rate_type: '/expected_rate/expected_rate_fee_rate_type',
        expected_rate_providers: '/expected_rate/expected_rate_providers',
        expected_rate_locations: '/expected_rate/expected_rate_locations',
        get_expected_rates: '/expected_rate/get_expected_rates',
        get_insurance_code: '/expected_rate/insurance_code',
        get_procedure_codes: '/expected_rate/procedure_codes',
        save_expected_rate: '/expected_rate/save_expected_rates',
        validate_expected_rates: '/expected_rate/validate_expected_rates',
    },
    patient_statement_urls: {
        companies_search: '/patient_statement/companies/search',
        companies_lookup: '/patient_statement/companies/lookup',
        create_patient_statement: '/patient_statement',
        reset_patient_statement: '/patient_statement/reset',
        batch_options: '/patient_statement/batch_options',
        requestPatientStatementProcess: '/patient_statement/requestPatientStatementProcess',
        requestPatientStatementProcessFile: '/patient_statement/requestPatientStatementProcessFile',
        resetPatientStatementRequestProcess: '/patient_statement/resetPatientStatementsProcess',
        patientStatementData: '/patient_statement/patientStatementData',
    },
    billing_urls: {
        rejection_group: {
            advanced_search: '/e277_status_group/search_e277_status_group',
            quick_search: '/e277_status_group/quick_search_e277_status_group'
        },
        e277_search_claims: '/payments/e277_claims',
        e277_close_claims: '/payments/close_e277_open_claims',
        demand_claims: {
            search : '/demand_claims/search',
            batch_claims : '/demand_claims/batch_claims',
            validate_charge : '/demand_claims/validate_charge',
            claims_report : '/demand_claims/claims_report',
        },
        batch_billing: {
            search: '/formatterhome/search',
            lookup: '/formatterhome/lookup',
            changestatus: '/formatterhome/changestatus',
            schedulecancel: '/formatterhome/schedulecancel',
            claims_detail: '/formatterhome/claimsdetail',
            get_edi_file: '/formatterhome/get_edi_file',
            schedule_run: '/formatterhome/schedule_run',
            manual_run: '/formatterhome/manual_run',
            manual_run_allow_override: '/formatterhome/manual_run_allow_override',
            run_set_details: '/formatterhome/run_set_details',
            update_run_status: '/formatterhome/update_run_status',
            print_claim_status: '/formatterhome/print_claim',
            obtain_print_claim_status: '/formatterhome/obtain_print_claim',
            clear_format_cache: '/formatterhome/clear_format_cache',
            get_last_format_flush: '/formatterhome/get_last_format_flush',
            get_batch_claims_export: '/formatterhome/batch_claims_export',
            obtain_batch_claims_export: '/formatterhome/obtain_batch_claims_export'
        },
        batch_updates: {
            master_data: '/batch_charge/master',
            master_data_by_company: '/batch_charge/master_by_company',
            search: '/batch_charge/search',
            update: '/batch_charge/update',
            update_batch_status: '/batch_charge/update_batch_status',
        }
    },
    referring_physician_status_end_urls: {
        search_incomplete: '/constant/referring_physicians/search/incomplete',
        update_status: '/constant/referring_physicians/update_status',
    },
    manage_constant: {
        constants: '/constants',
        copy_selected_sub_constants: '/constants/keys',
        sub_constants: '/constants/sub_constants'
    },
    roles_and_permissions: {
        base: 'roles_and_permission',
        roles: 'get_roles',
        permission_sets_list: 'get_permission_sets',
        get_permission_list: 'get_permissions',
        update_permissions: 'update_permissions',
        get_role: 'get_role',
        get_permission_set: 'get_permission_set',
        search_r2_role: 'search_r2_role',
        get_role_with_permission: 'get_role_with_permission',
        add_update_role: 'add_update_role',
        add_update_permission_set: 'add_update_permission_set',
        update_role_status: 'remove_role',
        update_permission_set_status: 'remove_permission_set'
    },
    integration_payment: {
        search_payment_integration: '/integration_payment/search_integration_payment',
        release_payment_integration: '/integration_payment/release_integration_payment',
        close_payment_integration: '/integration_payment/close_integration_payment'
    },
    claim_overrides: {
        claim_overrides_by_company: '/claim_overrides/overrides_by_company',
        refresh_override_data: '/claim_overrides/refresh_override_data',
        saveClaimOverrides: '/claim_overrides/save',
        claim_override_by_settings: '/claim_overrides/override_by_settings',
        insurance_class_ids: '/claim_overrides/insurance_class_ids',
        validate_override_settingname_and_collision: '/claim_overrides/validate_override_settingname_and_collision'
    },
    payers: {
        errors: '/payers/errors',
        error_exclusions: '/payers/errors/exclusions'
    },
    manage_payers: {
        auto_ftp_payers: '/ftp_manager/auto_ftp_payers',
        auto_ftp_active_payers: '/ftp_manager/auto_ftp_payers/active_payers'
    },
    ftp_management: {
        get_schedules: '/ftp_schedule/auto_ftp_schedules',
    },
    delete_account_notes: {
        get_patient_account_notes: '/delete_account_note/patient_account_notes',
        inactivate_patient_account_notes: '/delete_account_note/inactivate_patient_notes',
    },
    remove_move_835s: {
        get_eob_keys: '/remove_move_835s/eob_keys',
        submit_remove_move:'/remove_move_835s'
    }
}
export const user_type = {
    Customer: 4
}
// Global level Constant to hold action, validation error, success messages. 

export const constants = {
    mandatory_fields: 'Please fill all the mandatory fields or check highlighted field values.',
    default_company_mandatory: 'Default Company Is Required.',
    general_support_error: 'Unable to process your request. Please contact Support.',
    LEFT_MENU_PERMISSION: 'USER:LEFT_MENU_PERMISSION',
    CLEAR_APPLICATION_CACHE: 'CLEAR:LOCALSTORAGE_CACHE',
    REFRESH_TOKEN: 'USER:REFRESH_TOKEN',
    REFRESH_STORE: 'USER:REFRESH_STORE',
    PAGE_SIZE: 5000,
    Quick_Search_Suggestion_List_Size: 100,
    not_specified: "Not Specified",
    multiple_entries: "* - Multiple Entries",
    IS_PASSWORD_EXPIRED: 'USER:PASSWORD_EXPIRED',
    AUTO_LOGOUT: 'AUTO_LOGOUT',
    HEADER_UI_CHANGE: 'HEADER:UI_CHANGE_EXPAND',
    PROMPT_FOR_LOGOUT: 'PROMPT_FOR_LOGOUT',
    SHOW_NOTES_MODAL: 'SHOW_NOTES_MODAL',
    ACTIVE_PATIENT_ID: 'ACTIVE_PATIENT_ID',
    UPDATE_PAYMENT_METHOD: 'UPDATE_PAYMENT_METHOD',
    CUSTOM_PROMPT: 'CUSTOM_PROMPT',
    UNSAVED_FORM_DATA_FOR_PROMPT: 'UNSAVED_FORM_DATA_FOR_PROMPT',
    SHOW_MODEL_POPUP_REPORT: 'SHOW_MODEL_POPUP_REPORT',
    DISPLAY_404_PAGE: 'DISPLAY_404_PAGE',
    SHOW_PREVENT_COMPANY_CHANGE_MODEL: 'SHOW_PREVENT_COMPANY_CHANGE_MODEL',

    // Patient statement Log Id which is same as in 'e_menu-mapping' table's ID column
    PATIENT_STATEMENT_LOG_MENU_ID: 109,

    // **************TOKEN CONSTANTS****************//
    //This variable is used to set the time interval for identifying if the token is expired or not
    // The time is given in minutes
    check_token_expire_interval: (1000 * 10 * 1),//1000*sec*min,
    check_sso_token_verify_time: (1000 * 60 * 5),//1000*sec*min,
    request_timeout: (1000 * 60 * 8),//1000*sec*min, 
    window_tab_focus_timeout: 300,
    //Time left before token expire
    // Refresh Token is called 2 min before the token expires
    refresh_token_left_time: (1000 * 60 * 2),//1000*sec*min
    idle_popup_warning_time: 60,// In seconds
    idle_time: 1000 * 60 * 119,// In milli seconds
    password_encrpytion_key: "webptbilling_app", // This must be same in server side (size must be 16 alphabets)
    email_pattern: '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[A-Za-z]{2,}$',
    middle_initial_invalid_message: 'A - Z',
    valid_email: 'Please enter valid email address.',
    valid_url:'Please enter valid url.',
    ten_digits_compulsory: 'Please enter 10 digits.',
    IMAGES_PATH: "./../src/assets/images/",
    ssn_compulsory: 'You must enter 9 digits.',
    zip_compulsory: 'Zip can be 5 or 9 digits.',
    tin_compulsory: 'Tax Identification Number (9 digits)',
    txc_compulsory: 'Taxonomy Code (10 characters)',
    tob_compulsory: 'Type of Bill (3 digits)',
    company_information: {
        current_open_month: "Current Open Month",
        company_tin: "Company TIN",
        rcm_hub: "RCM Hub",
        contact_information: "Contact Information",
        online_patient_payments: "Online Patient Payments",
    },
    // all advance search pop name same as mention in API XML data
    advanced_control_type: {
        location: 'LOCATION',
        provider: 'PROVIDER',
        referring_physician: "REFERRING_PHYSICIAN",
        second_referring_physician: "SECOND_REFERRING_PHYSICIAN",
        referral_source: 'REFERRAL_SOURCE',
        search_referral_source_type: 'SEARCH_REFERRAL_SOURCE_TYPE',
        special_program: "SPECIAL_PROGRAM",
        diagnosiscode_search: 'DIAGNOSISCODE_SEARCH',
        primary_insurance: 'PRIMARY_INSURANCE',
        secondary_insurance: 'SECONDARY_INSURANCE',
        tertiary_insurance: 'TERTIARY_INSURANCE',
        search_insurance: 'SEARCH_INSURANCE',
        insurance_class: 'INSURANCE_CLASS',
        payer_description: 'PAYER_DESCRIPTION',
        claim_adjustment_code: 'CLAIM_ADJUSTMENT_CODE',
        patient: 'PATIENT',
        treating_provider: 'TREATING_PROVIDER',
        procedure_code: 'PROCEDURE_CODE',
        revenue_center: 'REVENUE_CENTER',
        admin_search_referral_source_type: 'ADMIN_SEARCH_REFERRAL_SOURCE_TYPE',
        admin_search_user_type: 'ADMIN_SEARCH_USER_TYPE',
        admin_search_role: 'ADMIN_SEARCH_ROLE',
        admin_company_search: 'ADMIN_COMPANY_SEARCH',
        parent_company: 'ADMIN_COMPANY_SEARCH',
        tax_procedure_code: 'PROCEDURE_CODE',
        excise_procedure_code: 'PROCEDURE_CODE',
        eob_payer: 'EOB_PAYER',
        rejection_group: 'REJECTION_GROUP',
        note_code: 'NOTE_CODE',
        group_code: 'GROUP_CODE',
        group_code_inactive: 'GROUP_CODE_INACTIVE',
        roles_search: 'ROLES_SEARCH',
        permission_search: 'PERMISSION_SEARCH',
        payment_code: 'PAYMENT_CODE',
        adjustment_code: 'ADJUSTMENT_CODE',
    },
    dropdown_default_info: {
        value_int: 0,
        value_string: "0",
        text: "Select"
    },
    default_date: '01/01/1900',
    required_text: '(required)',
    no_records_found_message: 'No Record Available',
}

export const date_format = {
    'mm/dd/yyyy': 'mm/dd/yyyy',
    'mm-dd-yyyy': 'mm-dd-yyyy',
    'yyyy': 'yyyy',
    'mm/dd/yyyy h:MM:ss TT': 'mm/dd/yyyy h:MM:ss TT',
    standard_date_time_format: 'mm/dd/yyyy hh:mm:ss',
    date_time_format: 'mm/dd/yyyy h:mm:ss TT',
    date_time_without_sec_format: 'mm/dd/yyyy h:MM TT',
    'mm/yyyy': 'mm/yyyy',
}

export const search_patient_configuration = {
    title: "Patient Search",
    control_defs: [{
        input: [{ label: "Company Name" }, { label: "Company Code" }],
        button: [{ name: "Search" }, { name: "Clear" }, { name: "Close" }]
    }],
    column_defs: [
        {
            headerName: "First Name",
            field: "name.first_name",
            filter: "agTextColumnFilter",
            filterParams: {
                applyButton: true,
                clearButton: true
            }
        },
        {
            headerName: "MI",
            field: "name.middle_initial",
            filter: "agTextColumnFilter",
        },
        {
            headerName: "Last Name",
            field: "name.last_name",
            filter: "agTextColumnFilter",
        },
        {
            headerName: "Date of Birth",
            field: "date_of_birth",
            filter: "agTextColumnFilter",
        },
        {
            headerName: "Patient ID",
            field: "id",
            filter: "agTextColumnFilter",
        },
        {
            headerName: "Alternate Patient ID",
            field: "alt_record_id",
            filter: "agTextColumnFilter",
        },
        {
            headerName: "SSN",
            field: "ssn",
            filter: "agTextColumnFilter",
        },
        {
            headerName: "City",
            field: "address.city",
            filter: "agTextColumnFilter",
        },
        {
            headerName: "Status",
            field: "is_active",
            filter: "agTextColumnFilter",
        },

    ]
};

// array of all the queued requests
export var request_queue = [];

// pages which NOT requires 'Patient Header'
export var payments_page_with_no_patient_header = [
    // Charges
    '/charge/procedure_code',
    '/charge/modifier',
    '/charge/charge_review_detail',
    '/charge/charge_audit',
    '/charge/revenue_center',
    // Payments
    '/payments/payment_code',
    '/payments/adjustment_code',
    '/payments/transaction_message_code',
    '/payments/claim_adjustment_code',
    '/payments/claim_adj_code_company',
    '/payments/claim_adj_code_pay_ov',
    '/payments/add_edit_payer_rules',
    // Admin
    '/admin/referring_physician',
    '/admin/referral_type',
    '/admin/special_program',
    '/admin/contract',
    '/admin/medical_group',
    '/admin/diagnosis_code',
    '/admin/referral_source',
    '/admin/payer_alert',
    '/admin/practice',
    '/admin/locations',
    '/admin/insurance',
    '/admin/provider',
    '/admin/company',
    '/admin/users',
    '/admin/batch_tasks',
    // Billing
    '/billing/provider_qualifier',
    '/billing/payer',
    '/billing/claim_indicator',
    '/billing/open_claims',
    '/billing/status_category',
    '/billing/status_code',
    '/billing/status_group_code',
    '/denial_management/open_zero_payments',
    '/billing/batch_billing'
];

// array for modules which NOT requires 'Patient Header'
export var no_patient_header =
    [
        '/coming_soon',
        '/404_page',
        '/patient_search',
        '/patient_registration',
        '/manage_account',
        '/report/report_data',
        '/report/close_eob',
        '/report/close_unrecognized_eob',
        '/report/update_unrecognized_eob',
        '/report/sub_report_data',
        '/payments/report/report_data',
        '/payments/report/sub_report_data',
        '/payments/reset_835',
        '/payments/release_835s',
        '/no_patient_selected',
        '/charge/charge_review',
        '/charge/revenue_center',
        '/report/charge_audit_summary',
        '/charge/updated_charges',
        '/charge/deactivate_charge_audit',
        '/charge/charge_audit',
        '/charge/release_claim_delay',
        '/admin/month_end',
        '/admin/batch_tasks',
        '/admin/schedule_new_job',
        '/admin/account_rollup',
        '/billing/277_report',
        '/charge/procedure_code_status',
        '/billing/patient_statements',
        '/patient_merge_accounts',
        '/billing/status_category',       
        '/billing/status_code',       
        '/billing/status_group_code',       
        '/admin/manage_constants',
        '/payments/add_edit_payer_rules'
    ];

// pages which NOT requires 'Patient Header'
export var r2_page_with_patient_header = ['/R2/last_patient_statement', '/R2/batch_payments', '/R2/add_adjustments', '/R2/add_group_adjustments', '/R2/edit_payments', '/R2/error_corrections']

// R2 embed pages which requires 'Patient Header'
export var show_empty_patient_header = ['/R2/batch_payments', '/R2/add_adjustments', '/R2/add_group_adjustments', '/R2/edit_payments', '/R2/error_corrections']

// array for modules which requires to clear patient id
export var clear_patient_header =
    [
        //'/coming_soon',
        '/patient_search',
        '/patient_registration',
        '/no_patient_selected'
    ];

// array for modules which navigate to patient view while patient's change
export var not_navigate_patient_view =
    [
        '/payments/unassigned_payments',
        '/charge/add_charge',
        '/charge/view_charge',
        '/payments/batch_payments',
        '/payments/batch_adjustments',
        '/billing/demand_claims',
        '/payments/error_corrections',        
        '/payments/error_corrections_details',        
        '/payments/edit_payments', 
        '/charge/charge_case_transfer' 
    ];
//url where no any header is required
export var no_header = [
    '/patient_search',
    '/coming_soon',
    '/patient_registration',
    '/404_page'
];
export const toastr_options = {
    toastr_time_out: 5000, //by setting to 0 it will prevent the auto close, value is in millli seconds
    newestOnTop: true,
    prevent_duplicates: true,
    position: "top-center",
    transition_in: "fadeIn",
    transition_out: "fadeOut",
    progress_bar: false
}

export const global_messages = {
    pdf_generation_error: 'PDF generation error.',
    popup_blocked: 'Please enable the blocked popup.'
}

export const prompt_for_logout = {
    NOT_REQUIRED: "NOT_REQUIRED",
    REQUIRED: "REQUIRED",
    PROMPT_REQUIRED: "PROMPT_REQUIRED",
    HANDLED: "HANDLED"
}
export const custom_prompt_stages = {
    INVOKE: "INVOKE",
    ONGOING: "ONGOING",
    CONTINUE: "CONTINUE",

}

export const custom_prompt_values = {
    COMPANY_CHANGE: "COMPANY_CHANGE",
    PATIENT_CHANGE: "PATIENT_CHANGE",
    CHANGE_FROM_REPORT: "CHANGE_FROM_REPORT"
}

export const SOURCE_CANCEL_BUTTON = "SOURCE_CANCEL_BUTTON";

export const MAX_TAB_COUNT = 5;

export const pendo_key = config.pendo_key;
export const launch_darkley = config.launch_darkley;

export enum CompanyServiceType {
    RevEquip = 1,//RevEquip
    RevServ = 2,//RevServ
    SoftwareOnly = 3//Software Only
}

// array for modules which requires to clear patient id
export var custom_prompt_routes_for_account_notes_popup =
    [
        '/login',
        '/patient_search',
        '/patient_registration',
        '/no_patient_selected'
    ];
export const DATE_RANGE_MEDICARE_CAP = {
    YEAR_MIN: 1896,
    YEAR_MAX: 2099
}
export enum enum_user_type_r3_id {
    Sys_Admin = 1,//System Administrator
    BMS = 2,//BMS
    Partner = 3,//Partner
    Customer = 4//Customer
}

export const url ={
    IDEAS_PORTAL_LINK: "http://ideas.webpt.com/",
	KNOWLEDGE_BASE_LINK: config.knowledge_base_link
}


export const charge_exception = {
    code: 500005,
    message: "Ouch! You've bumped heads with another user.</br></br>Refresh to get the latest data.",
    text:"Someone modified these data, Please take the latest data."
   
}

export const case_stale_exception = {
    code: 51000,
    message: "Ouch! You've bumped heads with another user.</br></br>Refresh to get the latest data.",
    text: "The case history request is stale."

}

export const charge_contains_payment_exception = {
    code: 500006,
    title: "Charge has Payment",
    message: "Wait...this charge has a payment, you'll want to check on that."
}

export const disable_constant_count_multi_select_draggable = {
    'modifiers' : 3,
    'diagnosis_codes' : 8
}

export const money_type = {
   CASH: "1",
   CHECK_NUM: "2",
   CREDIT_CARD_NUM: "3",
   DEBIT_CARD_NUM: "4",
   HSA: "5"
}

//Response Interfaces
export interface R6ResponseData {
    data: any;
    status: R6ResponseStatus;
    messages: Array<R6ErrorMessage>;
}

export enum R6ResponseStatus {
    failure = 0,
    success
}

export const http_response_status = {
    OK: 200
 }

export interface R6ErrorMessage {
    code: number;
    message: string;
}

//Dropdown interface
export interface IDropDownDataDto {
    key: string,
    value: string,
    text: string
}

//Dropdown interface
export interface IMultiSelectDropDownDataDto {
    name: string;
    value: string;
    on_hover_msg: string;
    description: string;
}

export const a_company_type = {
    Therapy_Source: 4,
    Web_PT: 5,
    Next_Gen: 7,
    Rev_Flow_3: 8
}

export const eob_claim_status_code = {
    1: 'Processed as Primary',
    2: 'Processed as Secondary',
    3:'Processed as Tertiary',
    4: 'Denied',
    19: 'Processed as Primary, Forwarded to Additional Payer(s)',
    20: 'Processed as Secondary, Forwarded to Additional Payer(s)',
    21: 'Processed as Tertiary, Forwarded to Additional Payer(s)',
    22: 'Reversal of Previous Payment',
    23: 'Not Our Claim, Forwarded to Additional Payer(s)',
    25: 'Predetermination Pricing Only - No Payment',
}

export const generic_error_message: string = 'Sorry, there was a system error while performing this operation.Please notify support if you continue to receive this error.';

export const alert_confirm_config =
{
    title: 'Unsaved Changes',
    display_message: 'This action will cause any unsaved data to be lost. Continue ?', 
    continue_button_text: 'Continue',
    cancel_button_text: 'Cancel'
}

export const code_sso_user_not_mapped = 300003;
export const universal_profile_session = "universal_profile";

export const override_field_dd = [
    { value: 0, text: 'Default', selected: false },
    { value: 1, text: 'Yes', selected: false },
    { value: 2, text: 'No', selected: false }
]

export const override_field_dd_value = {
    Default: 0,
    Yes: 1,
    No: 2

}