import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { Button, Dimmer, Checkbox, Form, Grid, Header, Loader, Input } from 'semantic-ui-react';
import * as moment from 'moment';
import TextareaAutosize from 'react-textarea-autosize';
import MaskedInput from 'react-text-mask';
import {isEmptyString} from '../../shared/utility';
import {
    get_company_full_detail,
    get_company_reference,
    add_company,
    edit_company,
    check_company_ff,
    get_clearinghouse_domains,
    quick_search_location,
    search_place,
    get_additional_settings,
    get_place_of_service
} from './../action/company_action';
import { get_company_logo } from '../../patient/action/patient_header_action';
import Selection from '../../shared/component/selection_component';
import DatePicker from '../../shared/component/date_picker_component';
import {
    loadMime,
    custom_date_format,
    get_all_error,
    unmask,
    zip_code_formation,
    unformat_zip_code as unformatted_zip_code,
    is_all_numbers,
    check_valid_npi,
    get_lauch_darkley_key_value as get_launch_darkly_key_value,
    toBoolean,
    date_format_with_no_time
} from '../../shared/utility';
import { set_focus_to_app_header } from './../../shared/tab_navigation_utility';
import IPPermissionComponent from '../../shared/component/ip_permission_component';
import AdvancedControl from './../../shared/component/advanced_control';
import AutoSearchComponent from './../../shared/component/auto_search_component';
import * as shared_constants from './../../shared/shared_constants';
import {
    procedure_code_advance_search,
    procedure_code_quick_search,
    get_companyList
} from './../../shared/action/autosearch_action';
import CurrencyFormatter from '../../shared/component/currency_formatter';
import * as global_constants from '../../global_constants';
import {
    enum_company_reference,
    enum_quick_search_type,
    company_messages,
    AllowOverrideFormatForDemandClaims,
    monthly_statement_batches_number,
    statement_batches_keys,
    restrictAccessToR4AtCompanyLevel,
    featureFlags,
    OnlinePaymentSupportFlag,
    clearinghouse_account_id_requirements
} from './../company_constants';
import * as company_action from './../../user/action/change_company_action';
import { search_company_configuration } from './../../user/user_constants';
import { content_length, content_width } from '../../shared/component/grid/utils';
import ConfirmationComponent from '../../shared/component/confirmation_component';
import * as session_storage from './../../shared/session_storage_utility';
import * as local_storage from './../../shared/local_storage_utility';
import { company, option_formater as option_formatter } from './../../admin/constants/constants';
import { refresh_token } from './../../login/action/login_action';
import { bindActionCreators } from 'redux';

export class CompanyComponent extends React.Component<any, any> {
    [x: string]: any;
    _is_mounted = false;
    image_formate: string = 'data:image/bmp;base64';
    image_preview: string = '';
    image_form_object = {};
    current_user_type: global_constants.enum_user_type_r3_id = 1;
    is_sysadmin: boolean = false;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            by_pass_prompt: false,
            show_use_formatter_confirm_popup: false,
            master_data: this.props.user_login_details ? this.props.user_login_details.formated_master_data : {},
            state_data: this.update_state_data(this.props.user_login_details.formated_master_data.states),
            drop_down_data: {},
            place_of_service: '',
            selected_location: { label: '' },
            is_edit_mode: props.location.pathname == '/admin/company' ? false : true,
            form_submitted: false,
            parent_company_search_data: {
            },
            update_child_ip_list: false,
            location_search_data: {
                "code": '',
                "name": '',
                "label": '',
                "id": ''
            },
            location_quick_search_format: {
                "code": '',
                "name": '',
                "label": '',
                "id": ''
            },
            company_quick_search_format: {
                "title": '',
                "name": '',
                "label": '',
                "gpms_code": '',
                "id": ''
            },
            tax_procedure_code_format: {
                "title": '',
                "label": '',
                "proc_code": '',
                "proc_desc1": '',
                "procedure_code_id": ''
            },
            excise_procedure_code_format: {
                "title": '',
                "label": '',
                "proc_code": '',
                "proc_desc1": '',
                "procedure_code_id": ''
            },
            grid_conf: {
                isPagination: true,
                gridAutoHeight: true,
                selectionType: 'single',
                paginationPageSize: 20,
            },
            company_search_data: {},
            tax_procedure_search_data: {},
            excise_procedure_search_data: {},
            company: {
                company_id: props.location.pathname == '/admin/company/add' ? 0 : props.match.params.company_id,
                company_name: null,
                mailing_address: null,
                mailing_city: null,
                mailing_state: null,
                mailing_zip: '',
                unformat_mailing_zip: '',
                is_po_box: false,
                logo_image_index_id: 0,
                image_name: null,
                image_base64: null,
                //member_setting
                company_code: null,
                parent_company_code: null,
                parent_company_id: 0,
                parent_company_name: null,
                hub_location_id: null,
                company_type_id: null,
                service_type_id: null,
                roll_up_type_id: null,
                tax_identification_number: null,
                tax_id_type: "true",                //its a boolean in json ntc
                open_month: null,
                formatted_open_month: null,
                closed_month: null,
                formatted_closed_month: null,
                use_formatter: true,
                include_in_reports: true,
                effective_date: null,
                formatted_effective_date: null,
                terminated_date: null,
                formatted_terminated_date: null,
                is_testor_demo: null,
                r2_Valid_ip_list: null,
                cpI_Page_URL: null,
                is_active: true,
                is_OPP_enabled: false,
                //contact information
                owner_name: null,
                owner_email: null,
                owner_phone: null,
                office_contact: null,
                office_email: null,
                office_phone: null,
                office_fax: null,
                fax_display_name: null,
                fax_display_address_and_phone: null,
                //claims information
                paper_claim_name: null,
                main_physical_address: null,
                main_physical_city: null,
                main_physical_state: null,
                main_physical_zip: '',
                unformat_main_physical_zip: '',
                company_npi: null,
                medicare_type: null,
                allow_conditional_billing: null,
                is_ptpn: null,
                is_zero_unit_validation: null,
                submitter_id: null,
                clearing_house_website: null,
                clearinghouse_Domain_Id: null,
                clearinghouse_Account_ID: null,
                //patient statement information
                statement_company_name: null,
                is_stat_return_addr_same_as_com: null,//Not found
                //Stmt_rtn_name: null,//bind on above checkbox checked not display on UI
                statement_return_address: null,
                statement_return_city: null,
                statement_return_state: null,
                statement_return_zip: '',
                unformat_statement_return_zip: '',
                statement_phone: null,
                statement_phone_ext: null,
                statement_header_info: null,
                credit_card_type: null,
                is_90_days_letter: null,
                is_apply_finance_charges: null,
                finance_charge_effective_date: null,
                formatted_finance_charge_effective_date: null,
                interest_rate: null,
                minimum_age_to_apply_finance_charge: null,
                maximum_finance_charge: '9999.99',
                finance_rate_ceiling: '99.99',
                small_balance_adjustment_amount: null,
                small_balance_adjustment_code: null,
                small_balance_adjustment_code_id: null,
                statement_note: null,
                //company setting
                reage_charge_flag: true,
                preaudit_hold_days: null,
                accrual_accounting: null,
                minimum_days_to_hold_payment: null,
                restrict_location_access: null,
                is_payright: null,
                allow_override_format_for_demand_claims: null,
                show_patient_previous_id: null,
                transaction_supply_charges: null,
                patient_email_required: null,
                allow_live_chat: false,
                //taxes
                tax_percent: null,
                tax_procedure_code_id: null,
                tax_procedure_code_desc: null,//Auto search
                tax_procedure_code: null,
                excise_tax_percentage: null,
                excise_tax_procedure_id: null,  //auto search
                excise_tax_proccode_desc: null,
                excise_tax_proccode_name: null,
                excise_tax_code: null,
                //invoice information
                is_same_as_company: false,
                inv_address: null,
                inv_address2: null,  //not found
                inv_city: null,
                inv_state: null,
                inv_zip: '',
                unformat_inv_zip: '',
                inv_billing_percent: null,
                intacct_id: null,
                is_webpt_revenue: true,
                //ip information
                ip_address: [],
                number_of_monthly_statement_batches: 1,
                preferred_statement_date1: 0,
                preferred_statement_date2: 0,
                preferred_statement_date3: 0,
                preferred_statement_date4: 0,
                location_id: 0
            },
            company_form_invalid: {
                company_name: false,
                company_code: false,
                service_type_id: false,
                office_fax: false,
                office_phone: false,
                inv_zip: false,
                main_physical_zip: false,
                mailing_zip: false,
                statement_return_zip: false,
                owner_email: false,
                office_email: false,
                owner_phone: false,
                statement_phone: false,
                company_npi: false,
                tax_identification_number: false,
                clearing_house_website: false,
                clearinghouse_Account_ID: false
            },
            clearing_house_domains: [],
            clearinghouse_account_id_input_enabled: false
        }
        this.ip_address_component_ref = React.createRef();
    }
    search_criteria = {
        company_name: '',
        company_code: '',
        show_inactive: false,
        mailing_state: ''
    };

    STATEMENT_HEADER_INFORMATION = {
        USE_COMPANY: "1",
        USE_LOCATION: "2"
    }

    patient_statement_info_for_use_company = {
        'statement_company_name': null,
        'is_stat_return_addr_same_as_com': null,
        'statement_return_address': null,
        'statement_return_city': null,
        'statement_return_state': null,
        'unformat_statement_return_zip': null,
        'statement_phone': null,
        'statement_phone_ext': null
    };
    rsiB31565StatementBatchesDesignateNumberOfMonthlyBatches = false;

    UNSAFE_componentWillMount = () => {
        var auth_data = local_storage.get("auth_data");
        if (auth_data && auth_data.user_type_r3_id) {
            this.current_user_type = auth_data.user_type_r3_id;
            this.is_sysadmin = auth_data.user_type_r3_id == global_constants.enum_user_type_r3_id.Sys_Admin;
        }
    };

    componentDidMount() {
        this._is_mounted = true;
        this.get_all_company_detail()
    };

    componentWillUnmount() {
        this._is_mounted = false;
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[2] !== 'company' && session_storage.remove('constant_company_criteria');
        }
    }

    get_all_company_detail = async () => {
        if (this._is_mounted) {
            this.setState({
                loading: true
            });
        }
        const arrData = [];
        const auth_data = local_storage.get("auth_data");

        const company_id = this.state.is_edit_mode
            ? this.state.company.company_id
            : auth_data.company_id;

        arrData.push(
            get_company_reference(
                this.props.user_login_details.user_data.data.token_details.access_token,
                this.state.is_edit_mode ? this.state.company.company_id : ''
            )
        );
        arrData.push(
            check_company_ff(this.props.user_login_details.user_data.data.token_details.access_token, {
                ff_name: 'rsi-b31565-statement-batches-designate-number-of-monthly-batches',
                company_id
            })
        );
        if (this.state.is_edit_mode) {
            arrData.push(
                get_company_full_detail(
                    this.props.user_login_details.user_data.data.token_details.access_token,
                    this.state.company.company_id
                )
            );
            arrData.push(
                get_company_logo(
                    this.state.company.company_id,
                    this.props.user_login_details.user_data.data.token_details.access_token
                )
            );
        }

        arrData.push(get_clearinghouse_domains(this.props.user_login_details.user_data.data.token_details.access_token));

        await Promise.all(arrData).then(
            (response) => {
                let {
                    drop_down_data,
                    company,
                    update_child_ip_list,
                    location_quick_search_format,
                    company_quick_search_format,
                    excise_procedure_code_format,
                    tax_procedure_code_format,
                    clearing_house_domains
                } = this.state;
                if (response[0].data && response[0].data.data && response[0].data.data.length > 0) {
                    Object.keys(enum_company_reference).map((item) => {
                        drop_down_data[item] = this.get_dropdown_value_based_on_type_initial(item, response[0].data.data);
                    });
                }
                if (response[1] && response[1].data && response[1].data.data) {
                    this.rsiB31565StatementBatchesDesignateNumberOfMonthlyBatches = response[1].data.data;
                }
                if (response[2] && response[2].data && response[2].data.data && response[2].data.data) {
                    // Set date object  for finance_charge_effective_date
                    if (
                        !(
                            response[2].data.data.finance_charge_effective_date == '' ||
                            response[2].data.data.finance_charge_effective_date == null ||
                            response[2].data.data.finance_charge_effective_date == undefined
                        )
                    ) {
                        response[2].data.data.formatted_finance_charge_effective_date = date_format_with_no_time(
                            response[2].data.data.finance_charge_effective_date
                        );
                    }
                    // Set date object  for closed_month
                    if (
                        !(
                            response[2].data.data.closed_month == '' ||
                            response[2].data.data.closed_month == null ||
                            response[2].data.data.closed_month == undefined
                        )
                    ) {
                        response[2].data.data.formatted_closed_month = date_format_with_no_time(response[2].data.data.closed_month);
                    }
                    // Set date object  for open_month
                    if (
                        !(
                            response[2].data.data.open_month == '' ||
                            response[2].data.data.open_month == null ||
                            response[2].data.data.open_month == undefined
                        )
                    ) {
                        response[2].data.data.formatted_open_month = date_format_with_no_time(response[2].data.data.open_month);
                    }
                    // Set date object  for open_month
                    if (
                        !(
                            response[2].data.data.effective_date == '' ||
                            response[2].data.data.effective_date == null ||
                            response[2].data.data.effective_date == undefined
                        )
                    ) {
                        response[2].data.data.formatted_effective_date = date_format_with_no_time(response[2].data.data.effective_date);
                    }
                    // Set date object  for open_month
                    if (
                        !(
                            response[2].data.data.terminated_date == '' ||
                            response[2].data.data.terminated_date == null ||
                            response[2].data.data.terminated_date == undefined
                        )
                    ) {
                        response[2].data.data.formatted_terminated_date = date_format_with_no_time(response[2].data.data.terminated_date);
                    }

                    if (response[2].data.data.ip_address && response[2].data.data.ip_address != null && response[2].data.data.ip_address.length > 0) {
                        response[2].data.data.ip_address = response[2].data.data.ip_address.map((item) => {
                            item['is_selected'] = false;
                            return item;
                        });
                    }
                    response[2].data.data.tax_id_type =
                        response[2].data.data.tax_id_type != null ? response[2].data.data.tax_id_type.toString() : '';
                    let online_payment_location_format = {
                        code: '',
                        name: '',
                        label: '',
                        id: ''
                    };
                    let company_format = {
                        title: '',
                        name: '',
                        label: '',
                        gpms_code: '',
                        id: ''
                    };
                    let tax_code_format = {
                        title: '',
                        label: '',
                        proc_code: '',
                        proc_desc1: '',
                        procedure_code_id: ''
                    };
                    let excise_tax_format = {
                        title: '',
                        label: '',
                        proc_code: '',
                        proc_desc1: '',
                        procedure_code_id: ''
                    };

                    if (response[2].data.data.location_id != null && response[2].data.data.location_id > 0) {
                        this.state.company.location_id = response[2].data.data.location_id
                    }

                    if (response[2].data.data.parent_company_id > 0) {
                        const _title =
                            response[2].data.data.parent_company_code == null || response[2].data.data.parent_company_code == ''
                                ? response[2].data.data.parent_company_name
                                : response[2].data.data.parent_company_code + ' - ' + response[2].data.data.parent_company_name;

                        company_format = {
                            title: response[2].data.data.parent_company_id.toString(),
                            name: _title,
                            gpms_code: response[2].data.data.parent_company_code,
                            label: _title,
                            id: response[2].data.data.parent_company_id.toString()
                        };
                    }
                    if (response[2].data.data.tax_procedure_code_id > 0) {
                        const _title =
                            response[2].data.data.tax_procedure_code_name == null ||
                                response[2].data.data.tax_procedure_code_name == ''
                                ? response[2].data.data.tax_procedure_code_desc
                                : response[2].data.data.tax_procedure_code_name +
                                ' - ' +
                                response[2].data.data.tax_procedure_code_desc;

                        tax_code_format = {
                            proc_code: response[2].data.data.tax_procedure_code_name,
                            proc_desc1: response[2].data.data.tax_procedure_code_desc,
                            procedure_code_id: response[2].data.data.tax_procedure_code_id.toString(),
                            title: response[2].data.data.tax_procedure_code_id.toString(),
                            label: _title
                        };
                    }

                    if (response[2].data.data.excise_tax_procedure_id > 0) {
                        const _title =
                            response[2].data.data.excise_tax_proccode_name == null ||
                                response[2].data.data.excise_tax_proccode_name == ''
                                ? response[2].data.data.excise_tax_proccode_desc
                                : response[2].data.data.excise_tax_proccode_name +
                                ' - ' +
                                response[2].data.data.excise_tax_proccode_desc;

                        excise_tax_format = {
                            title: response[2].data.data.excise_tax_procedure_id.toString(),
                            proc_desc1: response[2].data.data.excise_tax_proccode_desc,
                            proc_code: response[2].data.data.excise_tax_proccode_name,
                            label: _title,
                            procedure_code_id: response[2].data.data.excise_tax_procedure_id.toString()
                        };
                    }
                    if (
                        !(
                            response[2].data.data.tax_percent == '' ||
                            response[2].data.data.tax_percent == null ||
                            response[2].data.data.tax_percent == undefined
                        )
                    ) {
                        response[2].data.data.tax_percent = parseFloat(response[2].data.data.tax_percent).toFixed(2);
                    }
                    if (
                        !(
                            response[2].data.data.excise_tax_percentage == '' ||
                            response[2].data.data.excise_tax_percentage == null ||
                            response[2].data.data.excise_tax_percentage == undefined
                        )
                    ) {
                        response[2].data.data.excise_tax_percentage = parseFloat(
                            response[2].data.data.excise_tax_percentage
                        ).toFixed(4);
                    }
                    if (
                        !(
                            response[2].data.data.inv_billing_percent == '' ||
                            response[2].data.data.inv_billing_percent == null ||
                            response[2].data.data.inv_billing_percent == undefined
                        )
                    ) {
                        response[2].data.data.inv_billing_percent = parseFloat(response[2].data.data.inv_billing_percent).toFixed(
                            4
                        );
                    }
                    if (
                        !(
                            response[2].data.data.interest_rate == '' ||
                            response[2].data.data.interest_rate == null ||
                            response[2].data.data.interest_rate == undefined
                        )
                    ) {
                        response[2].data.data.interest_rate = parseFloat(response[2].data.data.interest_rate).toFixed(4);
                    }

                    response[2].data.data.unformat_mailing_zip = unformatted_zip_code(response[2].data.data.mailing_zip);
                    response[2].data.data.mailing_zip = zip_code_formation(response[2].data.data.mailing_zip);

                    response[2].data.data.unformat_main_physical_zip = unformatted_zip_code(
                        response[2].data.data.main_physical_zip
                    );
                    response[2].data.data.main_physical_zip = zip_code_formation(response[2].data.data.main_physical_zip);

                    response[2].data.data.unformat_statement_return_zip = unformatted_zip_code(
                        response[2].data.data.statement_return_zip
                    );
                    response[2].data.data.statement_return_zip = zip_code_formation(response[2].data.data.statement_return_zip);

                    response[2].data.data.unformat_inv_zip = unformatted_zip_code(response[2].data.data.inv_zip);
                    response[2].data.data.inv_zip = zip_code_formation(response[2].data.data.inv_zip);

                    // initially set company data which will be used when changed patient header
                    this.patient_statement_info_for_use_company['statement_company_name'] =
                        response[2].data.data['statement_company_name'];
                    this.patient_statement_info_for_use_company['is_stat_return_addr_same_as_com'] =
                        response[2].data.data['is_stat_return_addr_same_as_com'];
                    this.patient_statement_info_for_use_company['statement_return_address'] =
                        response[2].data.data['statement_return_address'];
                    this.patient_statement_info_for_use_company['statement_return_city'] =
                        response[2].data.data['statement_return_city'];
                    this.patient_statement_info_for_use_company['statement_return_state'] =
                        response[2].data.data['statement_return_state'];
                    this.patient_statement_info_for_use_company['unformat_statement_return_zip'] =
                        response[2].data.data['unformat_statement_return_zip'];
                    this.patient_statement_info_for_use_company['statement_phone'] = response[2].data.data['statement_phone'];
                    this.patient_statement_info_for_use_company['statement_phone_ext'] =
                        response[2].data.data['statement_phone_ext'];

                    // in case of location header type clear up details of patient statement header
                    if (
                        this.for_location_header_type(response[2].data.data.statement_header_info) &&
                        this.for_revequip_or_software_only_service_type(response[2].data.data.service_type_id)
                    ) {
                        response[2].data.data['statement_company_name'] = null;
                        response[2].data.data['is_stat_return_addr_same_as_com'] = null;
                        response[2].data.data['statement_return_address'] = null;
                        response[2].data.data['statement_return_city'] = null;
                        response[2].data.data['statement_return_state'] = null;
                        response[2].data.data['unformat_statement_return_zip'] = null;
                        response[2].data.data['statement_phone'] = null;
                        response[2].data.data['statement_phone_ext'] = null;
                    }

                    response[2].data.data.is_same_as_company = false;
                    if (
                        response[2].data.data.mailing_address == response[2].data.data.inv_address &&
                        response[2].data.data.mailing_city == response[2].data.data.inv_city &&
                        response[2].data.data.mailing_state == response[2].data.data.inv_state &&
                        response[2].data.data.mailing_zip == response[2].data.data.inv_zip
                    ) {
                        response[2].data.data.is_same_as_company = true;
                    }
                    company = response[2].data.data;
                    update_child_ip_list = true;
                    company_quick_search_format = company_format;
                    excise_procedure_code_format = excise_tax_format;
                    tax_procedure_code_format = tax_code_format;
                }
                if (response[3] && response[3].data && response[3].data.data) {
                    if (response[3].data.status) {
                        this.image_preview = response[3].data.data;
                    } else {
                        if (response[3].data.messages) {
                            const toastr_options = this.show_html_content_toaster(get_all_error(response[3].data.messages));
                            toastr.error('', toastr_options);
                        }
                    }
                }

                // Clearinghouse ID, with blank option (Select) and Id = 0
                if (this.state.is_edit_mode) {
                    if (response[4] && response[4].data && response[4].data.data) {
                        let clearinghouseDomains = response[4].data.data;
                        clearing_house_domains = option_formatter(clearinghouseDomains, 'clearinghouseDomainName', 'clearinghouseDomainId', true, false, 'Select', '0');
                    }
                } else {
                    if (response[2] && response[2].data && response[2].data.data) {
                        let clearinghouseDomains = response[2].data.data;
                        clearing_house_domains = option_formatter(clearinghouseDomains, 'clearinghouseDomainName', 'clearinghouseDomainId', true, false, 'Select', '0');
                    }
                }

                if (this._is_mounted) {
                    this.setState(
                        {
                            drop_down_data,
                            company,
                            update_child_ip_list,
                            loading: false,
                            location_quick_search_format,
                            company_quick_search_format,
                            excise_procedure_code_format,
                            tax_procedure_code_format,
                            clearing_house_domains
                        },
                        () => {
                            if (this.state.is_edit_mode && this._is_mounted) {
                                this.setState({ update_child_ip_list: false });
                            }
                        }
                    );
                    this.determine_clearinghouse_account_id_input_availability(company["service_type_id"], company["clearinghouse_Domain_Id"]);
                }
            },
            (error) => {
                if (this._is_mounted) {
                    this.setState({ loading: false });
                }
                if (error.response.data) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toastr.error('', toastr_options);
                }
            }
        );

        const arrLocationData = [];
        if (this.state.company.location_id != null) {
            arrLocationData.push(
                get_place_of_service(
                    this.state.company.location_id,
                    this.props.user_login_details.user_data.data.token_details.access_token
                )
            );

            await Promise.all(arrLocationData).then(
                (item) => {
                    let online_payment_location_format = {
                        code: '',
                        name: '',
                        label: '',
                        id: ''
                    };
                    if (item != null &&
                        item[0] != null &&
                        item[0].data != null &&
                        item[0].data.data != null) {

                        online_payment_location_format = {
                            code: item[0].data.data.pos_code,
                            name: item[0].data.data.pos_name,
                            label: item[0].data.data.pos_name,
                            id: item[0].data.data.place_of_service_id
                        };


                        if (this._is_mounted) {
                            this.setState({
                                location_search_data: online_payment_location_format
                            });
                        }
                    }

                },
                (error) => {
                    if (this._is_mounted) {
                        this.setState({ loading: false });
                    }
                    if (error.response.data) {
                        const toastr_options = this.show_html_content_toaster('Error retrieving online payment locatin data');
                        toastr.error('', toastr_options);
                    }
                }
            );
        }

    }

    update_state_data = (states) => {
        var state_data = Object.assign([], states);
        var select_status = state_data.filter(select => { return select.text == 'Select'; })
        if (select_status.length <= 0) {
            var select = {
                "text": "Select",
                "value": " ",
                "key": 'Select',
            }
            state_data.unshift(select);
            return state_data;
        }
        return state_data;
    }

    on_company_form_change_for_txt = (value, e) => {
        this.on_company_form_change(e, value, 'txt')
    }

    on_company_form_change_for_ddl = (value, e) => {
        this.on_company_form_change(e, value, 'ddl')
    }
    // get the searched value which is typed for searching location or providers
    get_input_value = (input, type) => {
        if (input.length >= 3) {
            this.get_location_data_list(input);
        }
    }

    get_location_data_list = async (search_keyword) => {
        const token = local_storage.get("auth_data").token_details.access_token;
        return await quick_search_location(search_keyword, token);
    }
    btn_set_focus = (element) => {
        if (this._is_mounted) {
            setTimeout(() => {
                if (element) {
                    element.focus();
                }
            }, 100);
        }
    }
    prepare_suggestion_online_payment = (data) => {
        let formattedList = [];
        var data_length = data.data.length;
        if (data && data_length) {
            for (let i = 0; i < data_length; i++) {
                let item = data.data[i];
                let name = typeof item.name === 'undefined' ? item.description : item.name;
                item = { ...item, title: name, label: item.code == '' ? name : item.code + ' - ' + name, key: `${i}-${name}` };
                formattedList.push(item);
            }
        }
        return formattedList;
    }
    close_location_modal = () => {
        this.btn_set_focus(this.button_location_clear);
    }
    update_data = (source, type) => {
        var source_length = source.length;

        var target = enum_company_reference.medicare_type == type || enum_company_reference.credit_card_type == type ? [{
            "text": 'Select',
            "value": ' ',
            "key": 'Select',
            "sequence": 0
        }] : [];
        if (source && source_length > 0) {
            for (let y = 0; y < source_length; y++) {
                let item = source[y];
                if (enum_company_reference.tax_id_type == type) {
                    target.push({
                        "text": item.title,
                        "value": item.value == 0 ? "false" : "true",
                        "key": item.title,
                        "sequence": item.sequence
                    })
                }
                else {
                    target.push({
                        "text": item.title,
                        "value": item.value == '' ? ' ' : item.value,
                        "key": item.title,
                        "sequence": item.sequence
                    })
                }

            }
        }
        return target;
    }

    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }

    get_dropdown_value_based_on_type_initial = (type, data) => {
        if (data.length > 0) {
            for (var indx = 0; indx < data.length; indx++) {
                if (data[indx].refrence_type == type) {
                    return this.update_data(data[indx].refrences, type);
                }
            }
        }
    }

    get_dropdown_value_based_on_type = (type) => {
        if (this.state.drop_down_data.length > 0) {
            var data = this.state.drop_down_data;
            for (var indx = 0; indx < data.length; indx++) {
                if (data[indx].refrence_type == type) {
                    return this.update_data(data[indx].refrences, type);
                }
            }
        }
    }

    on_company_form_change = (e, value, field_type) => {
        //common function call when any control's value changes
        var company_data = { ...this.state.company };
        const state_company_data = { ...this.state.company };
        var company_invalid = this.state.company_form_invalid;
        const field_name = field_type.startsWith("preferred_statement_date") ? field_type : e.target.name != undefined ? e.target.name.replace('txt_', '') : e.target.name;
        var field_value = '';
        if (field_type === 'chb') {
            field_value = value.checked;
            company_data[field_name] = field_value;
        } else {
            field_value = field_type.startsWith("preferred_statement_date") ? value : e.target.value != undefined ? e.target.value : e.target.value;
            if (field_type == 'phone') {
                field_value = unmask(field_value);
            }

            if (field_type === 'zip') {
                var unformat_value = unformatted_zip_code(field_value);
                field_value = unformat_value;
                if (field_value != null) {
                    field_value = is_all_numbers(field_value) ? (Number(field_value) <= 2147483647 ? field_value : company_data['unformat_' + field_name]) : company_data['unformat_' + field_name];
                    company_data['unformat_' + field_name] = unformatted_zip_code(field_value);
                }
                if (field_value.length > 5 && field_value.length <= 9) {
                    field_value = zip_code_formation(field_value);
                }
                company_data[field_name] = field_value;
            }
            else if (field_type == 'ext') {
                if (field_value != null) {
                    field_value = is_all_numbers(field_value) ? (Number(field_value) <= 2147483647 ? field_value : company_data[field_name]) : company_data[field_name];
                }
                company_data[field_name] = field_value;
            }
            else if (field_type === 'percent') {
                var isnum = /^-?\d*(\.\d{0,2})?$/.test(field_value);
                var mask_value_whole = field_value.replace('-', '').split('.')[0]
                if (isnum && mask_value_whole.length <= 5) {
                    company_data[field_name] = field_value;
                }
            }
            else if (field_type === 'decimal_four_digit') {
                var isnum = /^-?\d*(\.\d{0,4})?$/.test(field_value);
                var mask_value = field_value.replace('-', '').split('.')[0]
                if (isnum && mask_value.length <= 5) {
                    company_data[field_name] = field_value;
                }
            }
            else if (field_type === 'num') {
                var isnum = /^\d*?$/.test(field_value);
                if (isnum) {
                    company_data[field_name] = field_value;
                }
            }
            else if (field_type === 'day') {
                field_value = is_all_numbers(field_value) ? (Number(field_value) <= 2147483647 ? field_value : company_data[field_name]) : company_data[field_name];
                company_data[field_name] = field_value;
            }
            else if (field_type === 'ddl' && (field_name == "statement_header_info" || field_name == "service_type_id")) {

                if ((
                    field_name == "statement_header_info" && // if statement_header_info dropdown is selected.
                    this.for_location_header_type(field_value) && // if value "use location" is selected
                    this.for_revequip_or_software_only_service_type(company_data["service_type_id"]) // if service type is preset to revequip and software only
                ) || // OR
                    (
                        field_name == "service_type_id" && // if service type dropdown is selected
                        this.for_revequip_or_software_only_service_type(field_value) && // if value selected in reveequip or software only
                        this.for_location_header_type(company_data["statement_header_info"]) // if location header type is preselected
                    )
                ) {
                    // create a backup of fields for patient statement info
                    this.patient_statement_info_for_use_company = {
                        'statement_company_name': company_data['statement_company_name'],
                        'is_stat_return_addr_same_as_com': company_data['is_stat_return_addr_same_as_com'],
                        'statement_return_address': company_data['statement_return_address'],
                        'statement_return_city': company_data['statement_return_city'],
                        'statement_return_state': company_data['statement_return_state'],
                        'unformat_statement_return_zip': company_data['unformat_statement_return_zip'],
                        'statement_phone': company_data['statement_phone'],
                        'statement_phone_ext': company_data['statement_phone_ext']
                    };

                    // also empty the value of these patient statement info fields
                    company_data['statement_company_name'] = null;
                    company_data['is_stat_return_addr_same_as_com'] = null;
                    company_data['statement_return_address'] = null;
                    company_data['statement_return_city'] = null;
                    company_data['statement_return_state'] = null;
                    company_data['unformat_statement_return_zip'] = null;
                    company_data['statement_phone'] = null;
                    company_data['statement_phone_ext'] = null;

                    company_invalid['statement_company_name'] = false;
                    company_invalid['statement_return_address'] = false;
                    company_invalid['statement_return_city'] = false;
                    company_invalid['statement_return_state'] = false;
                    company_invalid['required_statement_return_zip'] = false;
                    company_invalid['statement_return_zip'] = false;
                    company_invalid['statement_phone'] = false;
                    company_invalid['required_statement_phone'] = false;
                }

                if (
                    (field_name == "statement_header_info" && // if statement_header_info dropdown is selected.
                        this.for_company_header_type(field_value) && // if value "use company" is selected
                        this.for_location_header_type(company_data["statement_header_info"]) && // if it is coming from "use location" header type
                        this.for_revequip_or_software_only_service_type(company_data["service_type_id"]) // if service type is preset to revequip and software only
                    ) ||  // OR                   

                    (field_name == "service_type_id" && // if service type dropdown is selected
                        this.for_revserv_service_type(field_value) && // if service type is selected to revserv
                        this.for_revequip_or_software_only_service_type(company_data["service_type_id"]) && // if service type is preset to revequip and software only
                        this.for_location_header_type(company_data["statement_header_info"]) // if header type is preselected to "use location"
                    )
                ) {

                    // if the statement header info is going to be use company and it is switching from use location
                    // restore the values which were backup up at the time of switching to use location
                    company_data['statement_company_name'] = this.patient_statement_info_for_use_company['statement_company_name'];
                    company_data['is_stat_return_addr_same_as_com'] = this.patient_statement_info_for_use_company['is_stat_return_addr_same_as_com'];
                    company_data['statement_return_address'] = this.patient_statement_info_for_use_company['statement_return_address'];
                    company_data['statement_return_city'] = this.patient_statement_info_for_use_company['statement_return_city'];
                    company_data['statement_return_state'] = this.patient_statement_info_for_use_company['statement_return_state'];
                    company_data['unformat_statement_return_zip'] = this.patient_statement_info_for_use_company['unformat_statement_return_zip'];
                    company_data['statement_phone'] = this.patient_statement_info_for_use_company['statement_phone'];
                    company_data['statement_phone_ext'] = this.patient_statement_info_for_use_company['statement_phone_ext'];

                    company_invalid = this.validate_patient_statement_details(company_invalid, company_data);
                }

                if (field_name == "service_type_id" &&
                    !this.for_revequip_or_software_only_service_type(company_data["service_type_id"]) && // if service type is preselected to revserv
                    this.for_revequip_or_software_only_service_type(field_value) && // if service type is selected to revequip or softwareonly
                    this.for_company_header_type(company_data["statement_header_info"]) // if "use company" header type is preselected
                    ||
                    (field_name == "statement_header_info" && // if statement_header_info dropdown is selected.
                        this.for_company_header_type(field_value) && // if value "use company" is selected
                        !this.for_location_header_type(company_data["statement_header_info"]) && // if it is comming from "use location" header type
                        this.for_revequip_or_software_only_service_type(company_data["service_type_id"]) // if service type is preset to revequip and softwareonly
                    )
                ) {
                    company_invalid = this.validate_patient_statement_details(company_invalid, company_data);
                }

                if (field_name == "service_type_id") {
                    company_data["allow_override_format_for_demand_claims"] = this.for_revserv_or_software_only_service_type(field_value);
                    company_invalid["clearinghouse_Account_ID"] = this.determine_clearinghouse_account_id_input_availability(value, company_data["clearinghouse_Domain_Id"]);
                    company_data["clearinghouse_Account_ID"] = "";
                }

                company_data[field_name] = field_value;

            }
            else if (field_type === 'number_of_monthly_statement_batches') {
                company_data[field_name] = field_value;
                company_data['preferred_statement_date1'] = 0;
                company_data['preferred_statement_date2'] = 0;
                company_data['preferred_statement_date3'] = 0;
                company_data['preferred_statement_date4'] = 0;
            }
            else if (field_name == "clearinghouse_Account_ID") {
                company_data[field_name] = value.value;
            }
            else {
                company_data[field_name] = field_value;
            }
            if (company_invalid[field_name] != undefined) {
                if (field_name == 'company_name' || field_name == 'company_code' || field_name == 'service_type_id'
                    || field_name == 'statement_company_name' || field_name == 'statement_return_address' || field_name == 'statement_return_city'
                    || field_name == 'statement_return_state'
                ) {
                    field_type = 'required';
                    if (field_name == "statement_return_state" && field_value) {
                        field_value = field_value.trim();
                    }
                }
                if (field_name == 'tax_identification_number') {
                    field_type = 'tin';
                }
                if (field_name == 'clearinghouse_Account_ID') {
                    if ((company_data["service_type_id"] == clearinghouse_account_id_requirements.required_service_type
                        && company_data["clearinghouse_Domain_Id"] == clearinghouse_account_id_requirements.required_domain_id)) {
                        field_type = 'required';
                    }
                }
                company_invalid[field_name] = !this.validate_value(field_type, field_value);

                if (field_name == "statement_phone") {
                    if (!field_value) {
                        company_invalid["required_statement_phone"] = true;
                    } else {
                        company_invalid["required_statement_phone"] = false;
                    }
                }

                if (field_name == "statement_return_zip") {
                    if (!field_value) {
                        company_invalid["required_statement_return_zip"] = true;
                    } else {
                        company_invalid["required_statement_return_zip"] = false;
                    }
                }
            }
            //TODO:Small Balance Adjustment Code
        }

        if (field_type == 'ddl' && field_name == 'clearinghouse_Domain_Id') {
            company_data[field_name] = field_value;
            company_invalid["clearinghouse_Account_ID"] = this.determine_clearinghouse_account_id_input_availability(company_data["service_type_id"], value);
            company_data["clearinghouse_Account_ID"] = "";
        }

        if (this._is_mounted) {
            this.setState(
                {
                    company: company_data,
                    company_form_invalid: company_invalid
                },
                () => {
                    if (
                        field_name == 'mailing_address' ||
                        field_name == 'mailing_city' ||
                        field_name == 'mailing_state' ||
                        field_name == 'mailing_zip' ||
                        field_name == 'statement_return_address' ||
                        field_name == 'statement_return_city' ||
                        field_name == 'statement_return_state' ||
                        field_name == 'statement_return_zip' ||
                        field_name == 'inv_address' ||
                        field_name == 'inv_city' ||
                        field_name == 'inv_state' ||
                        field_name == 'inv_zip'
                    ) {

                        let ignore_selection_of_patient_statement_information = false;
                        let ignore_selection_of_invoice_information = false;

                        if (field_name == 'inv_address' || field_name == 'inv_city' || field_name == 'inv_state' || field_name == 'inv_zip') {
                            ignore_selection_of_patient_statement_information = true;
                        }
                        if (field_name == 'statement_return_address' || field_name == 'statement_return_city' || field_name == 'statement_return_state' || field_name == 'statement_return_zip') {
                            ignore_selection_of_invoice_information = true;
                        }
                        if (!ignore_selection_of_invoice_information) {
                            company_data.is_same_as_company = this.check_same_company_details(true);
                        }
                        if (!ignore_selection_of_patient_statement_information) {
                            company_data.is_stat_return_addr_same_as_com = this.check_same_company_details(false);
                        }
                        if (this._is_mounted) {
                            if ((!ignore_selection_of_invoice_information && (company_data.is_same_as_company != state_company_data.is_same_as_company)) ||
                                (!ignore_selection_of_patient_statement_information && (company_data.is_stat_return_addr_same_as_com != state_company_data.is_stat_return_addr_same_as_com))) {
                                this.setState({
                                    company: company_data
                                });
                            }
                        }
                    }
                }
            );
        }
    }

    validate_patient_statement_details = (company_invalid, company_data) => {
        if (company_data['statement_return_state']) {
            company_data['statement_return_state'] = company_data['statement_return_state'].trim();
        }

        company_invalid['statement_company_name'] = !company_data['statement_company_name'] ? true : false;
        company_invalid['statement_return_address'] = !company_data['statement_return_address'] ? true : false;
        company_invalid['statement_return_city'] = !company_data['statement_return_city'] ? true : false;
        company_invalid['statement_return_state'] = !company_data['statement_return_state'] ? true : false;
        company_invalid['statement_return_zip'] = !this.validate_value("zip", company_data['unformat_statement_return_zip']);
        company_invalid['required_statement_return_zip'] = !company_data['unformat_statement_return_zip'] ? true : false;
        company_invalid['statement_phone'] = !this.validate_value("phone", company_data['statement_phone']);
        company_invalid['required_statement_phone'] = !company_data['statement_phone'] ? true : false;
        return company_invalid;
    }

    handle_calendar_change = (dt, field_name) => {
        let m = date_format_with_no_time(dt);
        const company_data = { ...this.state.company };

        if (m.isValid()) {
            company_data[field_name] = m;
        } else {
            company_data[field_name] = null;
        }

        if (this._is_mounted) {
            this.setState({
                company: company_data,
            });
        }
    };

    amount_change = (value, field_name) => {
        var company_data = { ...this.state.company };
        company_data[field_name] = Number(value);

        if (this._is_mounted) {
            this.setState({
                company: company_data,
            });
        }
    }

    validate_value(field_type, field_value) {
        switch (field_type) {
            case 'email':
                if (!field_value) return true;
                var email_invalid = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(field_value);
                return email_invalid;
            case 'npi':
                if (!field_value) return true;
                return check_valid_npi(field_value);
            case 'phone':
                if (!field_value) return true;
                return !(!field_value || (field_value && field_value.length > 0 && field_value.length != 10));
            case 'zip':
                if (!field_value) return true;
                var valid_zip = true;
                field_value = unformatted_zip_code(field_value);
                if ((field_value.length > 0 && field_value.length < 5) || (field_value.length > 5 && field_value.length < 9)) {
                    valid_zip = false;
                }
                return valid_zip;
            case 'tin':
                if (!field_value) return true;
                return !(!field_value || (field_value && field_value.length > 0 && field_value.length != 9));
            case 'required':
                var is_valid = true;
                if (!field_value) {
                    is_valid = false
                } else {
                    is_valid = true
                }
                return is_valid;
            case 'url':
                if (!field_value) return true;
                var url_invalid = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i.test(field_value);
                return url_invalid;
            case 'default':
                return true;
        }
    }

    determine_clearinghouse_account_id_input_availability(service_type_id, clearinghouse_domain_id) {
        var enabled = (service_type_id == clearinghouse_account_id_requirements.required_service_type && clearinghouse_domain_id == clearinghouse_account_id_requirements.required_domain_id);
        this.setState({ clearinghouse_account_id_input_enabled: enabled });
        return enabled;
    }

    on_clearinghouse_account_id_change(event, value, type) {
        value.value = this.sanitizeInput(value.value);
        this.on_company_form_change(event, value, type);
    }

    sanitizeInput(input: string): string {
        if (!input) return "";
        const regex = /^[a-zA-Z0-9]{1,100}$/;
        if (regex.test(input)) {
            return input;
        }
        return input.replace(/[^a-zA-Z0-9]/g, '').substring(0, 100);
    }

    get_update_ip_address_list = () => {
        if (this.ip_address_component_ref) {
            return this.ip_address_component_ref.get_update_ip_address_list();
        }
    }

    check_ip_address_validation = () => {
        if (this.ip_address_component_ref) {
            return this.ip_address_component_ref.validate_ip_address_list();
        }
        return true;
    }

    validate_form_field_data = (data, validate_field) => {
        var in_valid_form_data = false;

        var company_Data = data;
        var valid = validate_field;
        //patient First Name
        if (company_Data.office_fax != null || company_Data.office_fax != undefined || company_Data.office_fax != '') {
            valid.office_fax = !this.validate_value('phone', company_Data['office_fax'])
            in_valid_form_data = valid.office_fax;
        } else {
            valid.office_fax = false;
        }
        if (company_Data.office_phone != null || company_Data.office_phone != undefined || company_Data.office_phone != '') {
            valid.office_phone = !this.validate_value('phone', company_Data['office_phone']);
            in_valid_form_data = valid.office_phone;
        } else {
            valid.office_phone = false;
        }
        if (company_Data.inv_zip != null || company_Data.inv_zip != undefined || company_Data.inv_zip != '') {
            valid.inv_zip = !this.validate_value('zip', company_Data['inv_zip']);
            in_valid_form_data = valid.inv_zip;
        } else {
            valid.inv_zip = false;
        }
        if (company_Data.main_physical_zip != null || company_Data.main_physical_zip != undefined || company_Data.main_physical_zip != '') {
            valid.main_physical_zip = !this.validate_value('zip', company_Data['main_physical_zip']);
            in_valid_form_data = valid.main_physical_zip;
        } else {
            valid.main_physical_zip = false;
        }
        if (company_Data.mailing_zip != null || company_Data.mailing_zip != undefined || company_Data.mailing_zip != '') {
            valid.mailing_zip = !this.validate_value('zip', company_Data['mailing_zip']);
            in_valid_form_data = valid.mailing_zip;
        } else {
            valid.mailing_zip = false;
        }
        if ((!this.disabled_statement_header_company_details()) && (company_Data.statement_return_zip != null || company_Data.statement_return_zip != undefined || company_Data.statement_return_zip != '')) {
            valid.statement_return_zip = !this.validate_value('zip', company_Data['statement_return_zip']);
            in_valid_form_data = true;
        } else {
            valid.statement_return_zip = false;
            valid.required_statement_return_zip = false;
        }
        if (company_Data.owner_email != null || company_Data.owner_email != undefined || company_Data.owner_email != '') {
            valid.owner_email = !this.validate_value('email', company_Data['owner_email']);
            in_valid_form_data = valid.statement_return_zip;
        } else {
            valid.owner_email = false;
        }
        if (company_Data.office_email != null || company_Data.office_email != undefined || company_Data.office_email != '') {
            valid.office_email = !this.validate_value('email', company_Data['office_email']);
            in_valid_form_data = true;
        } else {
            valid.office_email = false;
        }
        if (company_Data.owner_phone != null || company_Data.owner_phone != undefined || company_Data.owner_phone != '') {
            valid.owner_phone = !this.validate_value('phone', company_Data['owner_phone']);
            in_valid_form_data = true;
        } else {
            valid.owner_phone = false;
        }
        if ((!this.disabled_statement_header_company_details()) && (company_Data.statement_phone != null || company_Data.statement_phone != undefined || company_Data.statement_phone != '')) {
            valid.statement_phone = !this.validate_value('phone', company_Data['statement_phone']);
            in_valid_form_data = true;
        } else {
            valid.statement_phone = false;
            valid.required_statement_phone = false;
        }

        if (company_Data.company_npi != null || company_Data.company_npi != undefined || company_Data.company_npi != '') {
            valid.company_npi = !this.validate_value('npi', company_Data['company_npi']);
            in_valid_form_data = true;
        } else {
            valid.company_npi = false;
        }
        if (company_Data.tax_identification_number != null || company_Data.tax_identification_number != undefined || company_Data.tax_identification_number != '') {
            valid.tax_identification_number = !this.validate_value('tin', company_Data['tax_identification_number']);
            in_valid_form_data = true;
        } else {
            valid.company_npi = false;
        }
        if (company_Data.tax_id_type != null || company_Data.tax_id_type != undefined) {
            company_Data.tax_id_type == 'true' ? company_Data.tax_id_type = true : company_Data.tax_id_type = false;
        }

        return valid;
    }

    set_default_values_for_null: any = (company_data: any) => {

        if (company_data.statement_phone_ext == ''
            || company_data.statement_phone_ext == null) {
            company_data.statement_phone_ext = 0;
        }
        if (company_data.minimum_age_to_apply_finance_charge == ''
            || company_data.minimum_age_to_apply_finance_charge == null) {
            company_data.minimum_age_to_apply_finance_charge = 0;
        }
        if (company_data.preaudit_hold_days == ''
            || company_data.preaudit_hold_days == null) {
            company_data.preaudit_hold_days = 0;
        }
        if (company_data.minimum_days_to_hold_payment == ''
            || company_data.minimum_days_to_hold_payment == null) {
            company_data.minimum_days_to_hold_payment = 0;
        }

        if (isEmptyString(company_data.inv_billing_percent)) {
            company_data.inv_billing_percent = null;
        }

        if (isEmptyString(company_data.excise_tax_percentage)) {
            company_data.excise_tax_percentage = null;
        }

        if (isEmptyString(company_data.tax_percent)) {
            company_data.tax_percent = null;
        }

        return company_data;
    }

    on_save_click = (bypass_use_formatter_check = false) => {
        //on save button click
        if (this._is_mounted) {
            this.setState({
                form_submited: true,
                show_use_formatter_confirm_popup: false,
                loading: true
            })
        }
        var company_data = { ...this.state.company };
        var company_invalid = { ...this.state.company_form_invalid };
        var empty_required_field = false;
        //trim the value before save
        company_data.company_name = company_data.company_name != null ? company_data.company_name.toString().trim() : company_data.company_name;
        company_data.company_code = company_data.company_code != null ? company_data.company_code.toString().trim() : company_data.company_code;

        if (!company_data.company_name) {
            company_invalid.company_name = true;
            empty_required_field = true;
        }

        if (!company_data.company_code) {
            company_invalid.company_code = true;
            empty_required_field = true;
        }
        if (!company_data.service_type_id) {
            company_invalid.service_type_id = true;
            empty_required_field = true;
        }

        if (this.state.clearinghouse_account_id_input_enabled && !company_data.clearinghouse_Account_ID) {
            company_invalid.clearinghouse_Account_ID = true;
            empty_required_field = true;
        }

        if (this.required_statement_header_company_details()) {
            if (!company_data.statement_company_name) {
                company_invalid.statement_company_name = true;
                empty_required_field = true;
            }
            if (!company_data.statement_return_address) {
                company_invalid.statement_return_address = true;
                empty_required_field = true;
            }
            if (!company_data.statement_return_city) {
                company_invalid.statement_return_city = true;
                empty_required_field = true;
            }
            if (!company_data.statement_return_state) {
                company_invalid.statement_return_state = true;
                empty_required_field = true;
            }
            if (!company_data.unformat_statement_return_zip) {
                company_invalid.required_statement_return_zip = true;
                empty_required_field = true;
            }
            if (!company_data.statement_phone) {
                company_invalid.required_statement_phone = true;
                empty_required_field = true;
            }
        } else {

            // filling invalid fields to false if not required statement header company details
            company_invalid.statement_company_name = false;
            company_invalid.statement_return_address = false;
            company_invalid.statement_return_city = false;
            company_invalid.statement_return_state = false;
            company_invalid.required_statement_return_zip = false;
            company_invalid.required_statement_phone = false;
        }

        if (empty_required_field) {
            if (this._is_mounted) {
                this.setState({
                    company_form_invalid: company_invalid,
                    loading: false
                });
            }
            toastr.error('', global_constants.constants.mandatory_fields);
            return;
        }
        var form_valid = this.validate_form_field_data(company_data, company_invalid);
        var self = this;
        var is_stop = false;
        Object.keys(form_valid)
            .forEach(function eachKey(key) {
                if (company_invalid[key]) {
                    is_stop = true;
                    if (self._is_mounted) {
                        self.setState({
                            loading: false,
                            company_form_invalid: form_valid
                        });
                    }
                    let el: HTMLElement = document.getElementsByName(key)[0];
                    if (el) {
                        el.focus();
                    }
                    return false;
                }
            });

        if (is_stop) {
            toastr.error('', global_constants.constants.mandatory_fields);
            return;
        }

        var is_ip_valid = this.check_ip_address_validation();
        if (!is_ip_valid) {
            if (this._is_mounted) {
                this.setState({
                    loading: false
                });
            }
            let el: HTMLElement = document.querySelector('#ip_access_section_table .error');
            if (el) {
                el.focus();
            }
            toastr.error('', global_constants.constants.mandatory_fields);
            return;
        }

        if (!bypass_use_formatter_check) {
            if (!company_data.use_formatter) {
                if (this._is_mounted) {
                    this.setState({
                        show_use_formatter_confirm_popup: true,
                        loading: false
                    })
                }
                return;
            }
        }


        var update_ip_list = this.get_update_ip_address_list();
        company_data.ip_address = update_ip_list;
        //convert formatted zip code to unformatted one
        company_data.mailing_zip = unformatted_zip_code(company_data.mailing_zip);
        company_data.main_physical_zip = unformatted_zip_code(company_data.main_physical_zip);
        company_data.statement_return_zip = unformatted_zip_code(company_data.statement_return_zip);
        company_data.inv_zip = unformatted_zip_code(company_data.inv_zip);

        //convert to string format from moment object for saving
        company_data.effective_date = company_data.formatted_effective_date == null ? null : company_data.formatted_effective_date;
        company_data.closed_month = company_data.formatted_closed_month == null ? null : company_data.formatted_closed_month;
        company_data.open_month = company_data.formatted_open_month == null ? null : company_data.formatted_open_month;
        company_data.terminated_date = company_data.formatted_terminated_date == null ? null : company_data.formatted_terminated_date;
        company_data.finance_charge_effective_date = company_data.formatted_finance_charge_effective_date == null ? null : company_data.formatted_finance_charge_effective_date;

        /////
        session_storage.set('constant_company_criteria', { search_criteria: this.search_criteria });
        if (!this.rsiB31565StatementBatchesDesignateNumberOfMonthlyBatches) {
            statement_batches_keys.forEach(e => delete company_data[e]);
        }

        //set default values for null fields
        company_data = this.set_default_values_for_null(company_data);

        // System.JSON is strict when it comes to types, it needs to be boolean, and its receiving string, making sure its always bool for save.
        company_data.tax_id_type = toBoolean(company_data.tax_id_type);
        company_data.location_id = this.state.company.location_id;

        if (this.state.company.company_id) {
            this.update_company(company_data);
        } else {
            this.add_company(company_data);
        }

    }

    on_close_use_formatter_confirm_popup = () => {
        if (this._is_mounted) {
            this.setState({
                show_use_formatter_confirm_popup: false,
                loading: false
            })
        }
    }
    on_place_search = async (params) => {
        const token = local_storage.get("auth_data").token_details.access_token;
        const search_data = await search_place(params, token).then(res => res.data);
        const search_result = search_data.data !== null ? search_data.data.result : [];
        const grid_data = {
            ...this.state.grid_conf, rows: search_result,
            column: shared_constants.search_location_configuration(`add-modal-location-${this.props.row_num}`).column_defs,
            messages: search_data.messages
        };
        this.setState({ location_search_data: grid_data });
    }

    search_place_of_service = async (locationid) => {
        const token = local_storage.get("auth_data").token_details.access_token;
        var data = get_additional_settings(locationid, token);
        return data;
    }

    get_place_of_service = place_of_service => {
        let pos = '';
        if (place_of_service) {
            const { code, name } = place_of_service;
            if (code) {
                pos = `${code}`;
            }
            if (name) {
                pos = `${pos} - ${name}`;
            }
        }
        return pos;
    };

    cancel_add_update = () => {
        this.props.history.goBack();
    }

    clear_quick_search_tax_procedure_code = () => {
        this.clear_quick_search(enum_quick_search_type.tax_procedure_code)
    }

    clear_quick_search_excise_procedure_code = () => {
        this.clear_quick_search(enum_quick_search_type.excise_procedure_code)
    }

    clear_quick_search_company = () => {
        this.clear_quick_search(enum_quick_search_type.company)
    }
    clear_quick_search_location = () => {
        this.clear_quick_search(enum_quick_search_type.location)
    }
    on_row_selection_advance_search_online_payment = (item) => {
        this.on_row_selection_advance_search(enum_quick_search_type.location, item)
    }

    on_row_selection_advance_search_tax_procedure_code = (item) => {
        this.on_row_selection_advance_search(enum_quick_search_type.tax_procedure_code, item)
    }

    on_row_selection_advance_search_excise_procedure_code = (item) => {
        this.on_row_selection_advance_search(enum_quick_search_type.excise_procedure_code, item)
    }

    on_row_selection_advance_search_company = (item) => {
        this.on_row_selection_advance_search(enum_quick_search_type.company, item)
    }

    handle_calendar_change_open_month = (e) => {
        this.handle_calendar_change(e, 'formatted_open_month')
    }

    handle_calendar_change_closed_month = (e) => {
        this.handle_calendar_change(e, 'formatted_closed_month')
    }

    handle_calendar_change_effective_date = (e) => {
        this.handle_calendar_change(e, 'formatted_effective_date')
    }

    handle_calendar_change_terminated_date = (e) => {
        this.handle_calendar_change(e, 'formatted_terminated_date')
    }

    handle_calendar_change_finance_charge_effective_date = (e) => {
        this.handle_calendar_change(e, 'formatted_finance_charge_effective_date')
    }

    add_company = (company_data) => {
        add_company(this.props.user_login_details.user_data.data.token_details.access_token, company_data).then(response => {
            if (response.data && response.data.status > 0) {
                this.cancel_add_update();
                toastr.success('', company_messages.added_successfully);
            } else {
                if (this._is_mounted) {
                    this.setState({ loading: false })
                }
                if (response.data.messages) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                    toastr.error('', toastr_options);
                }
            }

        }, (error) => {
            if (this._is_mounted) {
                this.setState({ loading: false })
            }
            if (error.response.data) {
                const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data.messages));
                toastr.error('', toastr_options);
            }
        })
        //add company information
    }

    update_company = (company_data) => {
        //update the company information
        edit_company(this.props.user_login_details.user_data.data.token_details.access_token, company_data).then(response => {
            if (response.data && response.data.status > 0) {

                var old_user_data = local_storage.get("auth_data");
                if (old_user_data) {
                    if (company_data.company_id > 0 && old_user_data.company_id == company_data.company_id) {
                        old_user_data.statement_header_info = company_data.statement_header_info;
                        old_user_data.company_service_type = company_data.service_type_id;
                        old_user_data.clearing_house_website = company_data.clearing_house_website;
                        old_user_data.allow_live_chat = company_data.allow_live_chat;
                        local_storage.set("auth_data", old_user_data);
                    }
                }
                this.cancel_add_update();
                toastr.success('', company_messages.updated_successfully);

            } else {
                if (this._is_mounted) {
                    this.setState({ loading: false })
                }
                if (response.data.messages) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                    toastr.error('', toastr_options);
                }
            }
        }, (error) => {
            if (this._is_mounted) {
                this.setState({ loading: false })
            }
            if (error.response.data) {
                const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                toastr.error('', toastr_options);
            }
        })
    }

    // Image upload handler.
    upload_logo = () => {
        let input: any = this.refs.input_reader;
        input.click();
    };

    // Image remove handler.
    remove_logo = () => {
        this.image_preview = null;
        var data = { ...this.state.company };
        data.logo_image_index_id = 0;
        data.image_name = null;
        data.image_base64 = null;

        if (this._is_mounted) {
            this.setState({
                company: data
            });
        }
        document.getElementById('remove_logo_btn').blur();
    };

    // Convert array buffer to base64
    arrayBufferToBase64(arrayBuffer) {
        return btoa(new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    }

    on_company_form_change_txt = (e, value) => {
        this.on_company_form_change(e, value, 'txt')
    }

    on_company_form_change_email = (e, value) => {
        this.on_company_form_change(e, value, 'email')
    }

    on_company_form_change_zip = (e, value) => {
        this.on_company_form_change(e, value, 'zip')
    }

    // Input File handler.
    file_handler = e => {
        const { name, files } = e.target;
        var is_valid_mime;
        if (this._is_mounted) {
            this.setState({
                logo_loading: true
            });
        }
        loadMime(files[0], (mime, is_valid, mime_type, file) => {
            //print the output to the screen
            is_valid_mime = is_valid;
            if (is_valid_mime) {
                var data = { ...this.state.company };
                let reader = new FileReader();
                let self = this;
                data.image_name = file.name;
                data.logo_image_index_id = null;
                reader.onload = (r: any) => {
                    this.image_preview = data[name] = r.target.result.split(',')[1];
                    this.image_formate = mime_type;
                    if (this._is_mounted) {
                        self.setState({
                            company: data,
                            logo_loading: false
                        });
                    }
                };
                reader.readAsDataURL(file);
                document.getElementById('upload_logo_btn').blur();
                this.is_image_updated = true;
            } else {
                toastr.error('', 'Only gif, jpg and png files can be uploaded');
            }
        });
    };

    // Advance search 
    on_company_grid_row_selection = (selected_row) => {
        this.on_row_selection_advance_search(enum_quick_search_type.company, selected_row);
    }
    on_location_grid_row_selection = (selected_row) => {
        this.on_row_selection_advance_search(enum_quick_search_type.location, selected_row);
    }
    on_tax_procedure_code_grid_row_selection = (selected_row) => {
        this.on_row_selection_advance_search(enum_quick_search_type.tax_procedure_code, selected_row);
    }

    on_excise_procedure_code_grid_row_selection = (selected_row) => {
        this.on_row_selection_advance_search(enum_quick_search_type.excise_procedure_code, selected_row);
    }

    on_row_selection_advance_search = (type_of_search, selected_row) => {
        var id = '';
        var name = '';
        var code = '';
        var selected_data = '';
        var company_state = this.state.company;
        if (type_of_search === enum_quick_search_type.company) {
            if (selected_row) {
                id = selected_row.id;
                name = selected_row.gpms_code == null ? selected_row.name : selected_row.gpms_code + ' - ' + selected_row.name;
            }
            var format_company = {
                "title": '',
                "name": '',
                "label": '',
                "gpms_code": '',
                "id": ''
            };
            if (parseInt(id) > 0) {
                // Set Auto search control for location
                format_company = {
                    "title": id.toString(),
                    "name": name,
                    "gpms_code": selected_row.gpms_code,
                    "label": name,
                    "id": id.toString()
                };
            }
            company_state.parent_company_code = format_company.gpms_code,
                company_state.parent_company_id = id;
            company_state.parent_company_name = selected_row.name;

            if (this._is_mounted) {
                this.setState({
                    company_quick_search_format: format_company,
                    company: company_state
                });
            }
        }
        else if (type_of_search === enum_quick_search_type.tax_procedure_code) {
            if (selected_row) {
                id = selected_row.procedure_code_id;
                if ((selected_row.proc_code != null && selected_row.proc_code != undefined) || (selected_row.name != null && selected_row.name != undefined)) {
                    code = (selected_row.proc_code == null && selected_row.proc_code == undefined) ? '' : selected_row.proc_code;
                    name = (selected_row.proc_desc1 == null && selected_row.proc_desc1 == undefined) ? '' : selected_row.proc_desc1;
                }
            }
            selected_data = code + " - " + name;
            var format_data = {
                "label": '', "title": '', "proc_code": '', "proc_desc1": '',
                "procedure_code_id": ''
            };
            if (parseInt(id) > 0) {
                // Set Auto search control for provider
                format_data = {
                    "title": id.toString(),
                    "label": selected_data,
                    "proc_code": selected_row.proc_code,
                    "proc_desc1": selected_row.proc_desc1,
                    "procedure_code_id": id.toString()
                };
            }
            company_state.tax_procedure_code_id = id,
                company_state.tax_procedure_code_desc = name;
            company_state.tax_procedure_code = code;
            if (this._is_mounted) {
                this.setState({
                    tax_procedure_code_format: format_data,
                    company: company_state,
                });
            }
        }
        else if (type_of_search === enum_quick_search_type.excise_procedure_code) {
            if (selected_row) {
                id = selected_row.procedure_code_id;
                if ((selected_row.proc_code != null && selected_row.proc_code != undefined) || (selected_row.name != null && selected_row.name != undefined)) {
                    code = (selected_row.proc_code == null && selected_row.proc_code == undefined) ? '' : selected_row.proc_code;
                    name = (selected_row.proc_desc1 == null && selected_row.proc_desc1 == undefined) ? '' : selected_row.proc_desc1;
                }
            }
            selected_data = code + " - " + name;
            var format_data = { "label": '', "title": '', "proc_code": '', "proc_desc1": '', "procedure_code_id": '' };
            if (parseInt(id) > 0) {
                // Set Auto search control for provider
                format_data = {
                    "title": id.toString(),
                    "label": selected_data,
                    "proc_code": selected_row.proc_code,
                    "proc_desc1": selected_row.proc_desc1,
                    "procedure_code_id": id.toString(),
                };
            }

            company_state.excise_tax_procedure_id = id,
                company_state.excise_tax_proccode_desc = name;
            company_state.excise_tax_proccode_name = code;
            if (this._is_mounted) {
                this.setState({
                    excise_procedure_code_format: format_data,
                    company: company_state,
                });
            }
        }
        else if (type_of_search === enum_quick_search_type.location) {
            if (selected_row) {
                id = selected_row.id;
                name = selected_row.name;
                code = selected_row.code;
            }
            var opp_location_format = {
                "code": '',
                "name": '',
                "label": '',
                "id": ''
            };
            if (parseInt(id) > 0) {
                // Set Auto search control for location
                opp_location_format = {
                    "code": code,
                    "name": name,
                    "label": name,
                    "id": id.toString()
                };
            }
            company_state.location_id = id
            if (this._is_mounted) {
                this.setState({
                    location_search_data: opp_location_format,
                    company: company_state
                });
            }
        }
    }

    column_def_format = (column_def, rows) => {
        if (rows && rows.length > 0) {
            let address1_length = 0, address2_length = 0;
            const result = rows.map((item) => {
                const text_address1_length = content_length(item.proc_desc1);
                if (text_address1_length > address1_length) {
                    address1_length = text_address1_length;
                }
                return item;
            });
            column_def = column_def.map(item => {
                if (item.field == 'proc_desc1') {
                    return { ...item, minWidth: content_width(address1_length) };
                }
                return item;
            })
        }
        return column_def;
    }

    // function/method to handle the when searching of location occurs
    on_company_search = async (params) => {
        this.configure_grid_data(enum_quick_search_type.company, params);
    }

    on_procedure_code_search = async (params) => {
        this.configure_grid_data(enum_quick_search_type.tax_procedure_code, params);
    }

    on_excise_procedure_code_search = async (params) => {
        this.configure_grid_data(enum_quick_search_type.excise_procedure_code, params);
    }

    // function/method to handle to configure data after the search happens in advance controls
    configure_grid_data = async (type_of_search, params) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        params = params ? params : {};
        var grid_data = [];
        if (type_of_search === enum_quick_search_type.company) {
            const search_data_company = await company_action.get_company_list(token, params.name, params.code).then(res => res.data);
            const search_company_result = search_data_company.data !== null ? search_data_company.data : [];
            grid_data = { ...this.state.grid_conf, rows: search_company_result, column: search_company_configuration.column_defs, messages: search_data_company.messages };
            if (this._is_mounted) {
                this.setState({ company_search_data: grid_data });
            }
        }

        else if (type_of_search === enum_quick_search_type.tax_procedure_code) {
            params.is_all_property = false;
            const search_data = await procedure_code_advance_search(params,
                this.props.user_login_details.user_data.data.token_details.access_token).then(res => res.data);
            const search_result = search_data.data !== null ? search_data.data.result : [];

            let col_def = this.column_def_format(shared_constants.search_procedure_code_configuration('tax_procedure_code_searcch').column_defs, search_result);
            grid_data = {
                ...this.state.grid_conf, rows: search_result,
                column: col_def,
                messages: search_data.messages
            };
            if (this._is_mounted) {
                this.setState({
                    tax_procedure_search_data: grid_data
                });
            }

        }
        else if (type_of_search === enum_quick_search_type.excise_procedure_code) {
            params.is_all_property = false;
            const search_data = await procedure_code_advance_search(params,
                this.props.user_login_details.user_data.data.token_details.access_token).then(res => res.data);
            const search_result = search_data.data !== null ? search_data.data.result : [];

            let col_def = this.column_def_format(shared_constants.search_procedure_code_configuration('excise_procedure_code_searcch').column_defs, search_result);
            grid_data = {
                ...this.state.grid_conf, rows: search_result,
                column: col_def,
                messages: search_data.messages
            };
            if (this._is_mounted) {
                this.setState({
                    excise_procedure_search_data: grid_data
                });
            }

        }
        else if (type_of_search === enum_quick_search_type.location) {
            const search_data = await procedure_code_advance_search(params,
                this.props.user_login_details.user_data.data.token_details.access_token).then(res => res.data);
            const search_result = search_data.data !== null ? search_data.data.result : [];
            let col_def = this.column_def_format(shared_constants.search_procedure_code_configuration('excise_procedure_code_searcch').column_defs, search_result);
            grid_data = {
                ...this.state.grid_conf, rows: search_result,
                column: col_def,
                messages: search_data.messages
            };
            if (this._is_mounted) {
                this.setState({
                    location_search_data: grid_data
                });
            }
        }
    }

    renderResult = (props) => {
        return (
            <div key={props.title} tabIndex={0} className="item_auto_search">
                <div key={props.title} className="fs_13">
                    <span>{props.label}</span>
                </div>
            </div>
        )
    }

    render_enable_online_patient_payments_field = () => {
        if (this.props.goliath_bill1938_online_payment_support) {
            return (<React.Fragment>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field >
                        <Checkbox
                            id="is_OPP_enabled_chb" label='Enable Online Patient Payments'
                            name='is_OPP_enabled'
                            checked={this.state.company.is_OPP_enabled || false}
                            onChange={(e, value) => this.on_company_form_change(e, value, 'chb')}
                        />
                    </Form.Field>
                </Grid.Column>
            </React.Fragment>)
        }
        return null;
    }

    render_allow_override_format_for_demand_claims_checkbox() {
        return (
            this.props.ff_sumaj_b32093_allow_override_format_for_demand_claims && (
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                        <Checkbox
                            id='allow_override_format_for_demand_claims'
                            label='Allow Override Format for Demand Claims'
                            name='allow_override_format_for_demand_claims'
                            checked={this.state.company.allow_override_format_for_demand_claims || false}
                            onChange={(e, value) => this.on_company_form_change(e, value, 'chb')}
                        />
                    </Form.Field>
                </Grid.Column>
            )
        );
    }

    get_company_list = async (inputValue) => {
        return await get_companyList(
            this.props.user_login_details.user_data.data.token_details.access_token,
            inputValue.trim(),
            global_constants.end_points.company_urls.company_lookup
        );
    }

    // get the quick search procedure code
    get_procedure_code_quick_search_data_list = async (search_keyword) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await procedure_code_quick_search(search_keyword, token);
    }

    prepare_suggestion_procedure = (_data) => {
        let formattedList = [];
        var data_length = 0;
        if (_data.data) {
            data_length = _data.data.length;
            _data = _data.data;
        }
        else {
            data_length = _data.length;
        }
        if (_data && data_length) {
            for (var i = 0; i < data_length; i++) {
                var item = _data[i];
                item = {
                    "title": `${item.procedure_code_id}`,
                    "label": (item.proc_code) + " - " + (item.proc_desc1),
                    "proc_code": item.proc_code,
                    "proc_desc1": item.proc_desc1,
                    "procedure_code_id": `${item.procedure_code_id}`
                };
                formattedList.push(item);
            }

        }
        return formattedList;
    }

    prepare_suggestion_company = (data) => {
        let formattedList = [];
        var data_length = data.data.length;
        if (data && data_length) {
            for (let i = 0; i < data_length; i++) {
                let item = data.data[i];
                formattedList.push({
                    "title": `${item.id}`,
                    "name": item.name,
                    "label": item.gpms_code == '' ? item.name : item.gpms_code + ' - ' + item.name,
                    "gpms_code": item.gpms_code,
                    "id": `${item.id}`
                })
            }
        }
        return formattedList;
    }

    setup_ip_reference = (instance) => {
        this.ip_address_component_ref = instance;
    }

    clear_quick_search = (type_of_search) => {
        var company_data = this.state.company;
        if (type_of_search == enum_quick_search_type.company) {
            company_data.parent_company_code = null,
                company_data.parent_company_id = null;
            company_data.parent_company_name = null;
            if (this._is_mounted) {
                this.setState({
                    company_quick_search_format: {
                        "title": '',
                        "name": '',
                        "label": '',
                        "gpms_code": '',
                        "id": ''
                    },
                    company: company_data,
                });
            }
        }
        else if (type_of_search == enum_quick_search_type.tax_procedure_code) {
            company_data.tax_procedure_code_id = null;
            company_data.tax_procedure_code_desc = null;
            company_data.tax_procedure_code = null;
            if (this._is_mounted) {
                this.setState({
                    tax_procedure_code_format: {
                        "title": '',
                        "label": '',
                        "proc_code": '', "proc_desc1": '', "procedure_code_id": ''
                    },
                    company: company_data,
                });
            }
        }
        else if (type_of_search == enum_quick_search_type.excise_procedure_code) {
            company_data.excise_procedure_code_id = null;
            company_data.excise_procedure_code_desc = null;
            company_data.excise_procedure_code = null;
            if (this._is_mounted) {
                this.setState({
                    excise_procedure_code_format: {
                        "title": '',
                        "label": '',
                        "proc_code": '', "proc_desc1": '',
                        "procedure_code_id": ''
                    },
                    company: company_data,
                });
            }
        }
        else if (type_of_search == enum_quick_search_type.location) {
            company_data.location_id = null;
            if (this._is_mounted) {
                this.setState({
                    location_search_data: {
                        "id": '',
                        "name": '',
                        "code": ''
                    },
                    company: company_data
                });
            }
        }
    }

    copy_data = (e, copy_for_invoice) => {
        var _company = Object.assign({}, this.state.company);
        var _invalid = Object.assign({}, this.state.company_form_invalid);
        var updated_company_data = {};
        if (e.target.checked) {
            updated_company_data = copy_for_invoice ?
                {
                    ...this.state.company,
                    is_same_as_company: e.target.checked,
                    inv_address: _company.mailing_address,
                    inv_city: _company.mailing_city,
                    inv_state: _company.mailing_state,
                    inv_zip: _company.mailing_zip,
                    unformat_inv_zip: _company.unformat_mailing_zip,

                } :
                {
                    ...this.state.company,
                    is_stat_return_addr_same_as_com: e.target.checked,
                    statement_return_address: _company.mailing_address,
                    statement_return_city: _company.mailing_city,
                    statement_return_state: _company.mailing_state,
                    statement_return_zip: _company.mailing_zip,
                    unformat_statement_return_zip: _company.unformat_mailing_zip,
                };
        }
        else {
            updated_company_data = copy_for_invoice ?
                { ...this.state.company, is_same_as_company: e.target.checked, } :
                { ...this.state.company, is_stat_return_addr_same_as_com: e.target.checked }
        }
        if (copy_for_invoice && updated_company_data['inv_zip']) {
            _invalid['inv_zip'] = !this.validate_value('zip', updated_company_data['inv_zip']);
        }
        else if (!copy_for_invoice) {
            if (updated_company_data['statement_return_zip']) {
                _invalid['statement_return_zip'] = !this.validate_value('zip', updated_company_data['statement_return_zip']);
            }

            if (e.target.checked && this.required_statement_header_company_details()) {
                _invalid['statement_return_address'] = !_company.mailing_address ? true : false;
                _invalid['statement_return_city'] = !_company.mailing_city ? true : false;
                _invalid['statement_return_state'] = !_company.mailing_state ? true : false;
                _invalid['required_statement_return_zip'] = !_company.unformat_mailing_zip ? true : false;
            }
        }
        if (this._is_mounted) {
            this.setState({
                company: updated_company_data,
                company_form_invalid: _invalid
            })
        }
    }

    check_same_company_details = (for_invoice) => {
        var state_company = this.state.company;
        var _same_as_company = false;
        if (for_invoice &&
            state_company.mailing_address == state_company.inv_address &&
            state_company.mailing_city == state_company.inv_city &&
            state_company.mailing_state == state_company.inv_state &&
            state_company.mailing_zip == state_company.inv_zip
        ) {
            _same_as_company = true;
        }
        if (!for_invoice &&
            state_company.mailing_address == state_company.statement_return_address &&
            state_company.mailing_city == state_company.statement_return_city &&
            state_company.mailing_state == state_company.statement_return_state &&
            state_company.mailing_zip == state_company.statement_return_zip
        ) {
            _same_as_company = true;
        }
        return _same_as_company;
    };

    for_revequip_or_software_only_service_type = (service_type_id) => {
        return service_type_id == 1 || service_type_id == 3;
    }

    for_revserv_or_software_only_service_type = (service_type_id) => {
        return service_type_id == 2 || service_type_id == 3;
    }

    for_revserv_service_type = (service_type_id) => {
        return service_type_id == 2;
    }

    for_location_header_type = (statement_header_info) => {
        return statement_header_info == this.STATEMENT_HEADER_INFORMATION.USE_LOCATION;
    }

    for_company_header_type = (statement_header_info) => {
        return statement_header_info == this.STATEMENT_HEADER_INFORMATION.USE_COMPANY;
    }
    required_statement_header_company_details = () => {
        setTimeout(() => { }, 3000); //state is slow to update, added timeout to wait for state to update
        if (this.props.goliath_bill1938_online_payment_support && this.state.company.is_OPP_enabled) {
            return true;
        }
        else {
            return this.for_company_header_type(this.state.company.statement_header_info)
                && this.for_revequip_or_software_only_service_type(this.state.company.service_type_id);
        }
    }

    disabled_statement_header_company_details = () => {
        setTimeout(() => { }, 3000); //state is slow to update, added timeout to wait for state to update
        if (this.props.goliath_bill1938_online_payment_support && this.state.company.is_OPP_enabled) {
            return false;
        }
        else {
            return this.for_location_header_type(this.state.company.statement_header_info) &&
                this.for_revequip_or_software_only_service_type(this.state.company.service_type_id);
        }
    }

    // Render Statement Batches
    render_statement_batches = () => {
        if (this.rsiB31565StatementBatchesDesignateNumberOfMonthlyBatches) {
            return (<React.Fragment><Grid.Column tablet={8} computer={4}>
                <Form.Field >
                    <label>Number of Statement Batches</label>
                    <Selection
                        placeHolder='Select'
                        id="number_of_monthly_statement_batches"
                        options={monthly_statement_batches_number}
                        name='number_of_monthly_statement_batches'
                        defaultValue={this.state.company.number_of_monthly_statement_batches || 1}
                        onChange={(e, value) => this.on_company_form_change(value, e, 'number_of_monthly_statement_batches')}
                        disabled={false}
                        hidden={true}
                    />
                </Form.Field>
            </Grid.Column></React.Fragment>)
        }
        return null;
    }

    render_online_payment_field = () => {
        if (this.props.goliath_bill1938_online_payment_support && this.state.company.is_OPP_enabled) {
            return (<React.Fragment>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field className={`advance-quick-search add-search-location-${this.props.row_num}`}>
                        <label>Default Online Payment Location</label>
                        <AdvancedControl
                            id={'search_place_grid_header_id'}
                            gridConfig={this.state.location_search_data}
                            onGridRowSelection={this.on_location_grid_row_selection}
                            onSearch={this.on_place_search}
                            onCloseModal={this.close_location_modal}
                            controlId={global_constants.constants.advanced_control_type.location}
                            search_type={global_constants.constants.advanced_control_type.location}
                            headerIdForGridTabNavigation={`search_place_grid_header_id`}
                        />
                        <AutoSearchComponent
                            default_value={this.state.location_search_data}
                            getInputValue={this.get_input_value}
                            getList={this.get_location_data_list}
                            prepareDataList={this.prepare_suggestion_online_payment}
                            selectresult={this.on_row_selection_advance_search_online_payment}
                            errorClass={this.props.is_submitted && this.state.err_place_of_service ? 'error' : ''}
                            errorMessage={'No Location Found !'}
                            is_focus={false}
                            is_disabled={this.props.disabled}
                            show_clear_search={true}
                            clear_search={() => this.clear_quick_search(enum_quick_search_type.location)}
                        />
                    </Form.Field>
                </Grid.Column>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field >
                        <Checkbox
                            id="90_days_letter_chb"
                            label='90 Days Letter'
                            name='is_90_days_letter'
                            checked={this.state.company.is_90_days_letter || false}
                            onChange={(e, value) => this.on_company_form_change(e, value, 'chb')}
                        />
                    </Form.Field>
                </Grid.Column>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field >
                        <Checkbox
                            id="apply_finance_charges_chb" label='Apply Finance Charges'
                            name='is_apply_finance_charges'
                            checked={this.state.company.is_apply_finance_charges || false}
                            onChange={(e, value) => this.on_company_form_change(e, value, 'chb')}
                        />
                    </Form.Field>
                </Grid.Column></React.Fragment>)
        }
        else {
            return (<React.Fragment>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                        <Checkbox
                            id="90_days_letter_chb"
                            label='90 Days Letter'
                            name='is_90_days_letter'
                            checked={this.state.company.is_90_days_letter || false}
                            onChange={(e, value) => this.on_company_form_change(e, value, 'chb')}
                        />
                    </Form.Field>
                </Grid.Column>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field >
                        <Checkbox
                            id="apply_finance_charges_chb" label='Apply Finance Charges'
                            name='is_apply_finance_charges'
                            checked={this.state.company.is_apply_finance_charges || false}
                            onChange={(e, value) => this.on_company_form_change(e, value, 'chb')}
                        />
                    </Form.Field>
                </Grid.Column></React.Fragment>)
        }
        return null;
    }
    render_restrict_access_to_r4_at_company_level_checkbox() {
        return (
            this.is_sysadmin &&
            this.props.ff_argos_b33306_remove_access_to_r4_with_overrides && (
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                        <Checkbox
                            id='restrict_access_to_r4'
                            label='Restrict Access to R4 at Company level'
                            name='restrict_access_to_r4'
                            checked={this.state.company.restrict_access_to_r4 || false}
                            onChange={(e, value) => this.on_company_form_change(e, value, 'chb')}
                        />
                    </Form.Field>
                </Grid.Column>
            )
        );
    }

    render_clearinghouse_domain_identifier() {
        return (
            this.props.ff_argos_bill1579_usc_ot_update && (
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field >
                        <label>Clearinghouse Domain Identifier </label>
                        <Selection
                            placeHolder='Select'
                            id="clearinghouse_Domain_Id"
                            options={this.state.clearing_house_domains}
                            name='clearinghouse_Domain_Id'
                            defaultValue={this.state.company.clearinghouse_Domain_Id || '0'}
                            onChange={this.on_company_form_change_for_ddl}
                            disabled={false}
                            hidden={true}
                        />
                    </Form.Field>
                </Grid.Column>
            )
        );
    }

    render_clearinghouse_account_id() {
        return (
            this.props.ff_leviathan_bill_2454_clearinghouse_account_id && (
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field >
                        <label>Clearinghouse Account ID</label>
                        <Input fluid
                            id='clearinghouse_Account_ID'
                            name='clearinghouse_Account_ID'
                            maxLength={100}
                            value={this.state.company.clearinghouse_Account_ID || ''}
                            onChange={(e, value) => this.on_clearinghouse_account_id_change(e, value, 'txt')}
                            disabled={!this.state.clearinghouse_account_id_input_enabled}
                            error={this.state.company_form_invalid.clearinghouse_Account_ID && this.state.form_submited}
                        />
                    </Form.Field>
                </Grid.Column>
            )
        );
    }

    render() {
        const required_statement_header_company_details = this.required_statement_header_company_details();
        const disable_statement_header_company_details = this.disabled_statement_header_company_details();
        return (
            <React.Fragment>
                <div className="common-forms common-forms-add-flex">
                    <Header as='h3' textAlign="left" id="applicationPageTitleSticky">
                        Company
                    </Header>
                    <div className="auto-height-patient-full-details-scroll" id="scrollable-content-area">
                        <Dimmer active={this.state.loading}>
                            <Loader size="massive">Loading</Loader>
                        </Dimmer>
                        <Form autoComplete="off" className="normal-form comapny_details_form">
                            <Grid className="form patient-search-form comapny_details_sub_section mar-t-5 ">
                                <Grid.Column id="company_information_section" computer={16} className="">
                                    <Header as='h2' textAlign="left" className="section-heading-mini_gray" content="Company Information" />
                                    <Grid>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Company Name <span className={this.state.company_form_invalid.company_name && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span></label>
                                                <Input fluid autoFocus
                                                    id='comapny_name_txt'
                                                    maxLength={50}
                                                    name='company_name'
                                                    value={this.state.company.company_name || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'txt')}
                                                    className={this.state.company_form_invalid.company_name && this.state.form_submited ? 'req-border-inp' : ''}
                                                    error={this.state.company_form_invalid.company_name && this.state.form_submited}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Mailing Address </label>
                                                <Input fluid id='mailing_address_txt'
                                                    name='mailing_address'
                                                    maxLength={50}
                                                    value={this.state.company.mailing_address || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'email')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Mailing City </label>
                                                <Input fluid id='mailing_city_txt'
                                                    name='mailing_city'
                                                    maxLength={35}
                                                    value={this.state.company.mailing_city || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'txt')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Mailing State </label>
                                                <Selection
                                                    placeHolder='Select'
                                                    name='mailing_state'
                                                    id="mailing_state_ddl"
                                                    options={this.state.state_data}
                                                    onChange={this.on_company_form_change_for_txt}
                                                    defaultValue={this.state.company.mailing_state || ''}
                                                    disabled={false}
                                                    hidden={true}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Mailing Zip </label>
                                                <Input fluid id='mailing_zip_txt'
                                                    autoComplete='new-password'
                                                    name='mailing_zip'
                                                    value={zip_code_formation(this.state.company.unformat_mailing_zip || '') || ''}
                                                    pattern='/^\d*?$/'
                                                    maxLength={10}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'zip')}
                                                    error={this.state.company_form_invalid.mailing_zip && this.state.form_submited}
                                                />
                                                <p className={this.state.company_form_invalid.mailing_zip && this.state.form_submited ? "required-place-bottom req-alert" : 'required-place-bottom'}><i>{this.state.company_form_invalid.mailing_zip && this.state.form_submited
                                                    && this.state.company.unformat_mailing_zip && this.state.company.unformat_mailing_zip != null && this.state.company.unformat_mailing_zip.length > 0
                                                    && (this.state.company.unformat_mailing_zip.length != 5 || this.state.company.unformat_mailing_zip.length != 9)
                                                    ? global_constants.constants.zip_compulsory : ''}
                                                </i></p>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>&nbsp;</label>
                                                <Checkbox
                                                    id="is_po_box_chb" label='P.O. Box'
                                                    name='is_po_box'
                                                    checked={this.state.company.is_po_box || false}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'chb')} />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            </Grid>
                            <br />
                            {
                                (this.current_user_type != global_constants.enum_user_type_r3_id.Partner
                                    && this.current_user_type != global_constants.enum_user_type_r3_id.Customer) ?
                                    <React.Fragment>
                                        <Grid className="form patient-search-form comapny_details_sub_section mar-t-5 ">
                                            <Grid.Column id="company_member_settings_section" computer={16} className="">
                                                <Header as='h2' textAlign="left" className="section-heading-mini_gray" content="Member Settings (Internal)" />
                                                <Grid>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <label>Company Code <span className={this.state.company_form_invalid.company_code && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span></label>
                                                            <Input fluid
                                                                id='company_code_txt'
                                                                name='company_code'
                                                                maxLength={50}
                                                                value={this.state.company.company_code || ''}
                                                                onChange={(e, value) => this.on_company_form_change(e, value, 'txt')}
                                                                className={this.state.company_form_invalid.company_code && this.state.form_submited ? 'req-border-inp' : ''}
                                                                error={this.state.company_form_invalid.company_code && this.state.form_submited}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field className="advance-quick-search">
                                                            <label>Parent Company </label>
                                                            <AdvancedControl
                                                                id={'search_company_grid_header_id'}
                                                                disabled_element={false}
                                                                onGridRowSelection={this.on_company_grid_row_selection}
                                                                gridConfig={this.state.company_search_data}
                                                                controlId={global_constants.constants.advanced_control_type.parent_company}
                                                                onSearch={this.on_company_search}
                                                                search_type={global_constants.constants.advanced_control_type.parent_company}
                                                                headerIdForGridTabNavigation={'search_company_grid_header_id'}
                                                            />
                                                            <AutoSearchComponent
                                                                default_value={this.state.company_quick_search_format}
                                                                errorMessage={'No Company Found !'}
                                                                prepareRenderList={this.renderResult}
                                                                getList={this.get_company_list}
                                                                prepareDataList={this.prepare_suggestion_company}
                                                                selectresult={this.on_row_selection_advance_search_company}
                                                                is_focus={false}
                                                                is_disabled={false}
                                                                show_clear_search={true}
                                                                clear_search={this.clear_quick_search_company}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <label>Hub Location </label>
                                                            <Selection
                                                                placeHolder='Select'
                                                                id="hub_location_id_ddl"
                                                                options={this.state.drop_down_data.hub_location}
                                                                name='hub_location_id'
                                                                onChange={this.on_company_form_change_for_txt}
                                                                defaultValue={this.state.company.hub_location_id || ''}
                                                                disabled={false}
                                                                hidden={true}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <label>Company Type </label>
                                                            <Selection
                                                                placeHolder='Select'
                                                                id="company_type_id_ddl"
                                                                options={this.state.drop_down_data.company_type}
                                                                name='company_type_id'
                                                                onChange={this.on_company_form_change_for_txt}
                                                                defaultValue={this.state.company.company_type_id || ''}
                                                                disabled={false}
                                                                hidden={true}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <label>Service Type <span className={this.state.company_form_invalid.service_type_id && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span></label>
                                                            <Selection
                                                                placeHolder='Select'
                                                                id="service_type_id_ddl"
                                                                options={this.state.drop_down_data.service_type}
                                                                name='service_type_id'
                                                                onChange={this.on_company_form_change_for_ddl}
                                                                defaultValue={this.state.company.service_type_id || ''}
                                                                disabled={false}
                                                                hidden={true}
                                                                isRequired={(this.state.company_form_invalid.service_type_id && this.state.form_submited) ? true : false}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <label>Roll-up Type </label>
                                                            <Selection
                                                                placeHolder='Select'
                                                                id="roll_up_type_ddl"
                                                                options={this.state.drop_down_data.roll_up_type}
                                                                name='roll_up_type_id'
                                                                onChange={this.on_company_form_change_for_txt}
                                                                defaultValue={this.state.company.roll_up_type_id || ''}
                                                                disabled={false}
                                                                hidden={true}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <label>Tax Identification Number </label>
                                                            <Input fluid id='tax_identification_number_txt'
                                                                name='tax_identification_number'
                                                                maxLength={9}
                                                                pattern="/^\d*?$/"
                                                                value={this.state.company.tax_identification_number || ''}
                                                                onChange={(e, value) => this.on_company_form_change(e, value, 'num')}
                                                                error={this.state.company_form_invalid.tax_identification_number && this.state.form_submited}
                                                            />
                                                            <p className={this.state.company_form_invalid.tax_identification_number && this.state.form_submited ? "required-for-billing-ssn-place-bottom req-alert" : 'required-for-billing-ssn-place-bottom'}><i>{this.state.company_form_invalid.tax_identification_number && this.state.form_submited
                                                                && this.state.company.tax_identification_number && this.state.company.tax_identification_number.length != 9 && this.state.company.tax_identification_number.length > 0
                                                                ? global_constants.constants.tin_compulsory : ''}
                                                            </i></p>
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <label>Tax ID Type </label>
                                                            <Selection
                                                                placeHolder='Select'
                                                                id="tax_id_type_ddl"
                                                                options={this.state.drop_down_data.tax_id_type}
                                                                name='tax_id_type'
                                                                onChange={this.on_company_form_change_for_txt}
                                                                defaultValue={this.state.company.tax_id_type || ''}
                                                                disabled={false}
                                                                hidden={true}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <label>Open Month </label>
                                                            <DatePicker id="open_month_date"
                                                                is_disabled={false}
                                                                date_update={this.handle_calendar_change_open_month}
                                                                date={this.state.company.formatted_open_month || null}
                                                                is_required={false}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <label>Closed Month </label>
                                                            <DatePicker id="closed_month_date"
                                                                is_disabled={false}
                                                                date_update={this.handle_calendar_change_closed_month}
                                                                date={this.state.company.formatted_closed_month || null}
                                                                is_required={false}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <label>Effective Date</label>
                                                            <DatePicker id="effective_date"
                                                                is_disabled={false}
                                                                date_update={this.handle_calendar_change_effective_date}
                                                                date={this.state.company.formatted_effective_date || null}
                                                                is_required={false}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <label>Terminated Date</label>
                                                            <DatePicker id="terminated_date"
                                                                is_disabled={false}
                                                                date_update={this.handle_calendar_change_terminated_date}
                                                                date={this.state.company.formatted_terminated_date || null}
                                                                is_required={false}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <label>RevFlow2 IP List </label>
                                                            <Input fluid
                                                                id='r2_Valid_ip_list_txt'
                                                                name='r2_Valid_ip_list'
                                                                value={this.state.company.r2_Valid_ip_list || ''}
                                                                onChange={(e, value) => this.on_company_form_change(e, value, 'txt')}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <label>CPI Page URL </label>
                                                            <Input fluid
                                                                id='cpi_page_url_txt'
                                                                name='cpI_Page_URL'
                                                                value={this.state.company.cpI_Page_URL || ''}
                                                                onChange={(e, value) => this.on_company_form_change(e, value, 'txt')}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <label>&nbsp;</label>
                                                            <Checkbox
                                                                id="use_formatter_chb"
                                                                label='Use Formatter'
                                                                name='use_formatter'
                                                                checked={this.state.company.use_formatter || false}
                                                                onChange={(e, value) => this.on_company_form_change(e, value, 'chb')}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <label className="no-below-desktop">&nbsp;</label>
                                                            <Checkbox
                                                                id="include_in_reports_chb"
                                                                label='Include in Reports'
                                                                name='include_in_reports'
                                                                checked={this.state.company.include_in_reports || false}
                                                                onChange={(e, value) => this.on_company_form_change(e, value, 'chb')}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <label className="no-below-desktop">&nbsp;</label>
                                                            <Checkbox
                                                                id="test_or_demo_chb"
                                                                label='Test or Demo'
                                                                name='is_testor_demo'
                                                                checked={this.state.company.is_testor_demo || false}
                                                                onChange={(e, value) => this.on_company_form_change(e, value, 'chb')}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <Checkbox
                                                                id="restrict_location_access_chb"
                                                                label='Restrict Location Access'
                                                                name='restrict_location_access'
                                                                checked={this.state.company.restrict_location_access || false}
                                                                onChange={(e, value) => this.on_company_form_change(e, value, 'chb')}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4} >
                                                        <Form.Field >
                                                            <Checkbox
                                                                id="allow_live_chat_chb"
                                                                label='Live Chat'
                                                                name='allow_live_chat'
                                                                checked={this.state.company.allow_live_chat || false}
                                                                onChange={(e, value) => this.on_company_form_change(e, value, 'chb')}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <Checkbox
                                                                id="is_payright_chb"
                                                                label='Is Payright'
                                                                name='is_payright'
                                                                checked={this.state.company.is_payright || false}
                                                                onChange={(e, value) => this.on_company_form_change(e, value, 'chb')}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    {this.render_restrict_access_to_r4_at_company_level_checkbox()}
                                                    {this.render_allow_override_format_for_demand_claims_checkbox()}
                                                    {this.render_enable_online_patient_payments_field()}
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <Checkbox
                                                                id="is_active_chb"
                                                                label='Is Active'
                                                                name='is_active'
                                                                checked={this.state.company.is_active || false}
                                                                onChange={(e, value) => this.on_company_form_change(e, value, 'chb')}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid>
                                            </Grid.Column>
                                        </Grid>
                                        <br />
                                    </React.Fragment>
                                    : null
                            }
                            <Grid className="form patient-search-form comapny_details_sub_section mar-t-5 ">
                                <Grid.Column id="company_information_section" computer={16} className="">
                                    <Header as='h2' textAlign="left" className="section-heading-mini_gray" content={"Contact Information"} />
                                    <Grid>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Owner Name </label>
                                                <Input fluid
                                                    id='owner_name_txt'
                                                    name='owner_name'
                                                    maxLength={50}
                                                    value={this.state.company.owner_name || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'txt')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Owner Email </label>
                                                <Input fluid id='owner_email_txt'
                                                    name='owner_email'
                                                    error={this.state.company_form_invalid.owner_email && this.state.form_submited}
                                                    maxLength={50}
                                                    value={this.state.company.owner_email || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'email')}
                                                />
                                                <div className='requiredText'>
                                                    {this.state.company_form_invalid.owner_email && this.state.form_submited ? global_constants.constants.valid_email : ''}
                                                </div>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Owner Phone </label>
                                                <MaskedInput
                                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    className={this.state.company_form_invalid.owner_phone && this.state.form_submited ? 'red-error-thin' : ''}
                                                    placeholder='(___) ___-____'
                                                    guide={true}
                                                    id='owner_phone_txt'
                                                    name='owner_phone'
                                                    autoComplete='new-password'
                                                    showMask={true}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'phone')}
                                                    value={this.state.company.owner_phone || ''}
                                                />
                                                <div className='requiredText'>
                                                    {this.state.company_form_invalid.owner_phone &&
                                                        this.state.form_submited
                                                        ? global_constants.constants.ten_digits_compulsory
                                                        : ''}
                                                </div>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Office Contact </label>
                                                <Input fluid id='office_contact_txt'
                                                    name='office_contact'
                                                    maxLength={50}
                                                    value={this.state.company.office_contact || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'txt')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Office Email</label>
                                                <Input fluid id='office_email_txt'
                                                    name='office_email'
                                                    error={this.state.company_form_invalid.office_email && this.state.form_submited}
                                                    maxLength={50}
                                                    value={this.state.company.office_email || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'email')}
                                                />
                                                <div className='requiredText'>
                                                    {this.state.company_form_invalid.office_email && this.state.form_submited ? global_constants.constants.valid_email : ''}
                                                </div>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Office Phone</label>
                                                <MaskedInput
                                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    className={this.state.company_form_invalid.office_phone && this.state.form_submited ? 'red-error-thin' : ''}
                                                    placeholder='(___) ___-____'
                                                    guide={true}
                                                    id='office_phone_txt'
                                                    name='office_phone'
                                                    autoComplete='new-password'
                                                    showMask={true}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'phone')}
                                                    value={this.state.company.office_phone || ''}
                                                />
                                                <div className='requiredText'>
                                                    {this.state.company_form_invalid.office_phone &&
                                                        this.state.form_submited
                                                        ? global_constants.constants.ten_digits_compulsory
                                                        : ''}
                                                </div>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Office Fax</label>
                                                <MaskedInput
                                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    className={this.state.company_form_invalid.office_fax && this.state.form_submited ? 'red-error-thin' : ''}
                                                    placeholder='(___) ___-____'
                                                    guide={true}
                                                    id='office_fax_txt'
                                                    name='office_fax'
                                                    autoComplete='new-password'
                                                    showMask={true}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'phone')}
                                                    value={this.state.company.office_fax || ''}
                                                />
                                                <div className='requiredText'>
                                                    {this.state.company_form_invalid.office_fax &&
                                                        this.state.form_submited
                                                        ? global_constants.constants.ten_digits_compulsory
                                                        : ''}
                                                </div>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Fax Display Name</label>
                                                <Selection
                                                    placeHolder='Select'
                                                    id="fax_display_name_ddl"
                                                    options={this.state.drop_down_data.fax_display_name}
                                                    name='fax_display_name'
                                                    defaultValue={this.state.company.fax_display_name || ''}
                                                    onChange={this.on_company_form_change_for_ddl}
                                                    disabled={false}
                                                    hidden={true}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Fax Display Address and Phone </label>
                                                <Selection
                                                    placeHolder='Select'
                                                    id="fax_display_address_and_phone_ddl"
                                                    options={this.state.drop_down_data.fax_display_address}
                                                    name='fax_display_address_and_phone'
                                                    defaultValue={this.state.company.fax_display_address_and_phone || ''}
                                                    onChange={this.on_company_form_change_for_ddl}
                                                    disabled={false}
                                                    hidden={true}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            </Grid>
                            <br />
                            <Grid className="form patient-search-form comapny_details_sub_section mar-t-5 ">
                                <Grid.Column id="claims_information_section" computer={16} className="">
                                    <Header as='h2' textAlign="left" className="section-heading-mini_gray" content="Claims Information" />
                                    <Grid>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Paper Claim Name</label>
                                                <Input fluid
                                                    id='paper_claim_name_txt'
                                                    name='paper_claim_name'
                                                    maxLength={40}
                                                    value={this.state.company.paper_claim_name || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'txt')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Main Physical Address</label>
                                                <Input fluid id='main_physical_address_txt'
                                                    name='main_physical_address'
                                                    maxLength={200}
                                                    value={this.state.company.main_physical_address || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'txt')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Main Physical City</label>
                                                <Input fluid id='main_physical_city_txt'
                                                    name='main_physical_city'
                                                    maxLength={100}
                                                    value={this.state.company.main_physical_city || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'txt')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Main Physical State </label>
                                                <Selection
                                                    placeHolder='Select'
                                                    id="main_physical_state_ddl"
                                                    options={this.state.state_data}
                                                    name='main_physical_state'
                                                    defaultValue={this.state.company.main_physical_state || ''}
                                                    onChange={this.on_company_form_change_for_ddl}
                                                    disabled={false}
                                                    hidden={true}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Main Physical Zip</label>
                                                <Input fluid id='main_physical_zip_txt'
                                                    name='main_physical_zip'
                                                    autoComplete='new-password'
                                                    maxLength={10}
                                                    pattern='[0-9]*'
                                                    value={zip_code_formation(this.state.company.unformat_main_physical_zip || '') || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'zip')}
                                                    error={this.state.company_form_invalid.main_physical_zip && this.state.form_submited}
                                                />
                                                <p className={this.state.company_form_invalid.main_physical_zip && this.state.form_submited ? "required-place-bottom req-alert" : 'required-place-bottom'}><i>{this.state.company_form_invalid.main_physical_zip && this.state.form_submited
                                                    && this.state.company.unformat_main_physical_zip && this.state.company.unformat_main_physical_zip != null && this.state.company.unformat_main_physical_zip.length > 0
                                                    && (this.state.company.unformat_main_physical_zip.length != 5 || this.state.company.unformat_main_physical_zip.length != 9)
                                                    ? global_constants.constants.zip_compulsory : ''}
                                                </i></p>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Company NPI</label>
                                                <Input fluid id='company_npi_txt'
                                                    name='company_npi'
                                                    maxLength={10}
                                                    value={this.state.company.company_npi || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'npi')}
                                                    error={this.state.company_form_invalid.company_npi && this.state.form_submited}
                                                />
                                                <p className={this.state.company_form_invalid.company_npi &&
                                                    this.state.form_submited ? "required-place-bottom req-alert" : 'required-place-bottom'}>
                                                    {this.state.company_form_invalid.company_npi &&
                                                        this.state.form_submited ? 'Invalid NPI (Example 1234567893)' : ''}
                                                </p>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Medicare Type</label>
                                                <Selection
                                                    id="medicare_type_ddl"
                                                    options={this.state.drop_down_data.medicare_type}
                                                    name='medicare_type'
                                                    defaultValue={this.state.company.medicare_type || ''}
                                                    onChange={this.on_company_form_change_for_ddl}
                                                    disabled={false}
                                                    hidden={true}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Submitter ID</label>
                                                <Input fluid id='submitter_id_txt'
                                                    name='submitter_id'
                                                    maxLength={250}
                                                    value={this.state.company.submitter_id || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'txt')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Clearinghouse Website</label>
                                                <Input fluid id='clearing_house_website_txt'
                                                    name='clearing_house_website'
                                                    error={this.state.company_form_invalid.clearing_house_website && this.state.form_submited}
                                                    maxLength={100}
                                                    value={this.state.company.clearing_house_website || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'url')}
                                                />
                                                <div className='requiredText'>
                                                    {this.state.company_form_invalid.clearing_house_website && this.state.form_submited ? global_constants.constants.valid_url : ''}
                                                </div>
                                            </Form.Field>
                                        </Grid.Column>
                                        {this.render_clearinghouse_domain_identifier()}
                                        {this.render_clearinghouse_account_id()}
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>&nbsp;</label>
                                                <Checkbox
                                                    id="allow_conditional_billing_chb"
                                                    label='Allow Conditional Billing'
                                                    name='allow_conditional_billing'
                                                    checked={this.state.company.allow_conditional_billing || false}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'chb')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label className="no-below-desktop">&nbsp;</label>
                                                <Checkbox
                                                    id="ptpn_chb" label='PTPN'
                                                    name='is_ptpn'
                                                    checked={this.state.company.is_ptpn || false}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'chb')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label className="no-below-desktop">&nbsp;</label>
                                                <Checkbox
                                                    id="no_zero_unit_validation_chb" label='No Zero Unit Validation'
                                                    name='is_zero_unit_validation'
                                                    checked={this.state.company.is_zero_unit_validation || false}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'chb')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            </Grid>
                            <br />
                            <Grid className="form patient-search-form comapny_details_sub_section mar-t-5 ">
                                <Grid.Column id="patient_statement_information_section" computer={16} className="">
                                    <Header as='h2' textAlign="left" className="section-heading-mini_gray" content="Patient Statement Information" />
                                    <Grid>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Statement/Online Payment Company Name{required_statement_header_company_details || (this.props.goliath_bill1938_online_payment_support && this.state.company.is_OPP_enabled) ?
                                                    <span className={this.state.company_form_invalid.statement_company_name && this.state.form_submited ? "req-alert" : 'req-alert_normal'}> (required)
                                                    </span> : ""
                                                }</label>
                                                <Input fluid
                                                    id='company_statement_name_txt'
                                                    name='statement_company_name'
                                                    maxLength={40}
                                                    value={this.state.company.statement_company_name || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'txt')}
                                                    className={required_statement_header_company_details && this.state.company_form_invalid.statement_company_name && this.state.form_submited ? 'req-border-inp' : ''}
                                                    error={required_statement_header_company_details && this.state.company_form_invalid.statement_company_name && this.state.form_submited}
                                                    disabled={disable_statement_header_company_details}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>&nbsp;</label>
                                                <Checkbox
                                                    id="statement_return_address_same_as_company_chb"
                                                    label='Statement Return Address Same as Company'
                                                    name='is_stat_return_addr_same_as_com'
                                                    checked={this.state.company.is_stat_return_addr_same_as_com || false}
                                                    onChange={(e, value) => this.copy_data(e, false)}
                                                    disabled={disable_statement_header_company_details}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Statement Return Address{required_statement_header_company_details ?
                                                    <span className={this.state.company_form_invalid.statement_return_address &&
                                                        this.state.form_submited ? "req-alert" : 'req-alert_normal'}> (required)
                                                    </span> : ""
                                                }</label>
                                                <Input fluid id='statement_return_address_txt'
                                                    name='statement_return_address'
                                                    maxLength={50}
                                                    value={this.state.company.statement_return_address || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'txt')}
                                                    className={required_statement_header_company_details && this.state.company_form_invalid.statement_return_address && this.state.form_submited ? 'req-border-inp' : ''}
                                                    error={required_statement_header_company_details && this.state.company_form_invalid.statement_return_address && this.state.form_submited}
                                                    disabled={disable_statement_header_company_details}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Statement Return City{required_statement_header_company_details ?
                                                    <span className={this.state.company_form_invalid.statement_return_city &&
                                                        this.state.form_submited ? "req-alert" : 'req-alert_normal'}> (required)
                                                    </span> : ""
                                                }</label>
                                                <Input fluid id='statement_return_city_txt'
                                                    name='statement_return_city'
                                                    maxLength={50}
                                                    value={this.state.company.statement_return_city || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'txt')}
                                                    className={required_statement_header_company_details && this.state.company_form_invalid.statement_return_city && this.state.form_submited ? 'req-border-inp' : ''}
                                                    error={required_statement_header_company_details && this.state.company_form_invalid.statement_return_city && this.state.form_submited}
                                                    disabled={disable_statement_header_company_details}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Statement Return State{required_statement_header_company_details ?
                                                    <span className={this.state.company_form_invalid.statement_return_state &&
                                                        this.state.form_submited ? "req-alert" : 'req-alert_normal'}> (required)
                                                    </span> : ""
                                                }</label>
                                                <Selection
                                                    placeHolder='Select'
                                                    id="statement_return_state_ddl"
                                                    options={this.state.state_data}
                                                    name='statement_return_state'
                                                    defaultValue={this.state.company.statement_return_state || ''}
                                                    onChange={this.on_company_form_change_for_ddl}
                                                    disabled={disable_statement_header_company_details}
                                                    hidden={true}
                                                    isRequired={(required_statement_header_company_details && this.state.company_form_invalid.statement_return_state && this.state.form_submited) ? true : false}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Statement Return ZIP Code{required_statement_header_company_details ?
                                                    <span className={(this.state.company_form_invalid.required_statement_return_zip ||
                                                        this.state.company_form_invalid.statement_return_zip) &&
                                                        this.state.form_submited ? "req-alert" : 'req-alert_normal'}> (required)
                                                    </span> : ""
                                                }</label>
                                                <Input fluid id='statement_return_zip_txt'
                                                    name='statement_return_zip'
                                                    autoComplete='new-password'
                                                    maxLength={10}
                                                    pattern='[0-9]*'
                                                    value={zip_code_formation(this.state.company.unformat_statement_return_zip || '') || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'zip')}
                                                    error={(required_statement_header_company_details && this.state.company_form_invalid.required_statement_return_zip || (this.state.company_form_invalid.statement_return_zip &&
                                                        this.state.company.unformat_statement_return_zip &&
                                                        this.state.company.unformat_statement_return_zip.length > 0)) && this.state.form_submited}
                                                    className={required_statement_header_company_details && (this.state.company_form_invalid.required_statement_return_zip ||
                                                        this.state.company_form_invalid.statement_return_zip) && this.state.form_submited ? 'req-border-inp' : ''}
                                                    disabled={disable_statement_header_company_details}
                                                />
                                                <p className={(this.state.company_form_invalid.required_statement_return_zip ||
                                                    this.state.company_form_invalid.statement_return_zip) && this.state.form_submited ? "required-place-bottom req-alert" : 'required-place-bottom'}><i>{this.state.company_form_invalid.statement_return_zip && this.state.form_submited
                                                        && this.state.company.unformat_statement_return_zip && this.state.company.unformat_statement_return_zip != null && this.state.company.unformat_statement_return_zip.length > 0
                                                        && (this.state.company.unformat_statement_return_zip.length != 5 || this.state.company.unformat_statement_return_zip.length != 9)
                                                        ? global_constants.constants.zip_compulsory : ''}
                                                    </i></p>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field className={required_statement_header_company_details && this.state.form_submited &&
                                                (this.state.company_form_invalid.required_statement_phone ||
                                                    this.state.company_form_invalid.statement_phone) ? "requiredWithBgColor error" : ''}>
                                                <label>Statement/Online Payment Phone{required_statement_header_company_details || (this.props.goliath_bill1938_online_payment_support && this.state.company.is_OPP_enabled) ?
                                                    <span className={(this.state.company_form_invalid.required_statement_phone ||
                                                        this.state.company_form_invalid.statement_phone) &&
                                                        this.state.form_submited ? "req-alert" : 'req-alert_normal'}> (required)
                                                    </span> : ""
                                                }</label>
                                                <MaskedInput
                                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    className={
                                                        (this.state.company_form_invalid.required_statement_phone || this.state.company_form_invalid.statement_phone) &&
                                                            this.state.company.statement_phone &&
                                                            this.state.company.statement_phone.length > 0
                                                            && this.state.form_submited ? 'red-error-thin' : ''}
                                                    placeholder='(___) ___-____'
                                                    guide={true}
                                                    id='statement_phone_txt'
                                                    name='statement_phone'
                                                    autoComplete='new-password'
                                                    showMask={true}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'phone')}
                                                    value={this.state.company.statement_phone || ''}
                                                    disabled={disable_statement_header_company_details}
                                                />
                                                <div className='requiredText'>
                                                    {(this.state.company_form_invalid.statement_phone ||
                                                        this.state.company_form_invalid.required_statement_phone
                                                    ) && this.state.company.statement_phone
                                                        && this.state.company.statement_phone.length > 0 &&
                                                        this.state.form_submited
                                                        ? global_constants.constants.ten_digits_compulsory
                                                        : ''}
                                                </div>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Statement Phone Ext.</label>
                                                <Input fluid id='statement_phone_ext_txt'
                                                    name='statement_phone_ext'
                                                    value={this.state.company.statement_phone_ext || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'ext')}
                                                    disabled={disable_statement_header_company_details}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Statement Header Information</label>
                                                <Selection
                                                    placeHolder='Select'
                                                    id="statement_header_information_ddl"
                                                    options={this.state.drop_down_data.statement_header_information}
                                                    name='statement_header_info'
                                                    defaultValue={this.state.company.statement_header_info || ''}
                                                    onChange={this.on_company_form_change_for_ddl}
                                                    disabled={false}
                                                    hidden={true}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        {this.render_statement_batches()}
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Credit Card Type</label>
                                                <Selection
                                                    id="credit_card_type_ddl"
                                                    options={this.state.drop_down_data.credit_card_type}
                                                    name='credit_card_type'
                                                    onChange={this.on_company_form_change_for_txt}
                                                    defaultValue={this.state.company.credit_card_type || ''}
                                                    disabled={false}
                                                    hidden={true}
                                                />

                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Finance Charge Effective Date</label>
                                                <DatePicker id="finance_charge_effective_date"
                                                    is_disabled={false}
                                                    date_update={this.handle_calendar_change_finance_charge_effective_date}
                                                    date={this.state.company.formatted_finance_charge_effective_date || null}
                                                    is_required={false}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Interest Rate</label>
                                                <Input fluid id='interest_rate_txt'
                                                    name='interest_rate'
                                                    pattern="^-?\d*(\.\d{0,4})?$"
                                                    value={this.state.company.interest_rate || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'decimal_four_digit')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Min. Age to Apply Finance Charge</label>
                                                <Input fluid id='minimum_age_to_apply_finance_charge_txt'
                                                    name='minimum_age_to_apply_finance_charge'
                                                    value={this.state.company.minimum_age_to_apply_finance_charge || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'day')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Maximum Finance Charge</label>
                                                <CurrencyFormatter
                                                    onChange={this.amount_change}
                                                    prefix='$'
                                                    id={'maximum_finance_charge_txt'}
                                                    maxLength={15}
                                                    defaultValue={(this.state.company.maximum_finance_charge ? this.state.company.maximum_finance_charge.toString() : "")}
                                                    allowNegative={false}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Finance Rate Ceiling</label>
                                                <CurrencyFormatter
                                                    onChange={this.amount_change}
                                                    prefix='$'
                                                    id={'finance_rate_ceiling_txt'}
                                                    maxLength={15}
                                                    defaultValue={(this.state.company.finance_rate_ceiling ? this.state.company.finance_rate_ceiling.toString() : "")}
                                                    allowNegative={false}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Small Balance Adjustment Amount</label>
                                                <CurrencyFormatter
                                                    onChange={this.amount_change}
                                                    prefix='$'
                                                    id={'small_balance_adjustment_amount_txt'}
                                                    maxLength={15}
                                                    defaultValue={(this.state.company.small_balance_adjustment_amount ? this.state.company.small_balance_adjustment_amount.toString() : "")}
                                                    allowNegative={false}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Small Balance Adjustment Code</label>
                                                <Selection
                                                    placeHolder='Select'
                                                    id="small_balance_adjustment_code_ddl"
                                                    options={this.state.drop_down_data.small_balance_adjustment_code}
                                                    name='small_balance_adjustment_code_id'
                                                    defaultValue={this.state.company.small_balance_adjustment_code_id || ''}
                                                    onChange={this.on_company_form_change_for_ddl}
                                                    disabled={false}
                                                    hidden={true}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Statement Note</label>
                                                <TextareaAutosize id='statement_note_txt'
                                                    name='statement_note'
                                                    maxLength={250}
                                                    value={this.state.company.statement_note || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'txtarea')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        {this.render_online_payment_field()}
                                    </Grid>
                                </Grid.Column>
                            </Grid>
                            <br />
                            <Grid className="form patient-search-form comapny_details_sub_section mar-t-5 ">
                                <Grid.Column id="company_settings_section" computer={16} className="">
                                    <Header as='h2' textAlign="left" className="section-heading-mini_gray" content="Company Settings" />
                                    <Grid>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>PreAudit Hold Days</label>
                                                <Input fluid
                                                    id='preaudit_hold_days_txt'
                                                    name='preaudit_hold_days'
                                                    value={this.state.company.preaudit_hold_days || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'day')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Minimum Days to Hold Payment</label>
                                                <Input fluid id='minimum_days_to_hold_payment_txt'
                                                    name='minimum_days_to_hold_payment'
                                                    maxLength={3}
                                                    value={this.state.company.minimum_days_to_hold_payment || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'day')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label className="no-below-desktop">&nbsp;</label>
                                                <Checkbox
                                                    id="re_age_charges_chb" label='Re-Age Charges'
                                                    name='reage_charge_flag'
                                                    checked={this.state.company.reage_charge_flag || false}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'chb')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label className="no-below-desktop">&nbsp;</label>
                                                <Checkbox
                                                    id="accrual_accounting_chb" label='Accrual Accounting'
                                                    name='accrual_accounting'
                                                    checked={this.state.company.accrual_accounting || false}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'chb')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <Checkbox
                                                    id="show_patient_previous_id_chb" label='Show Patient Previous ID'
                                                    name='show_patient_previous_id'
                                                    checked={this.state.company.show_patient_previous_id || false}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'chb')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <Checkbox
                                                    id="transaction_supply_charges_chb" label='Transaction Supply Charges'
                                                    name='transaction_supply_charges'
                                                    checked={this.state.company.transaction_supply_charges || false}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'chb')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <Checkbox
                                                    id="patient_email_required_chb" label='Patient Email Required'
                                                    name='patient_email_required'
                                                    checked={this.state.company.patient_email_required || false}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'chb')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            </Grid>
                            <br />
                            <Grid className="form patient-search-form comapny_details_sub_section mar-t-5 ">
                                <Grid.Column id="company_taxes_section" computer={16} className="">
                                    <Header as='h2' textAlign="left" className="section-heading-mini_gray" content="Taxes" />
                                    <Grid>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Tax Percent</label>
                                                <Input fluid
                                                    pattern="^-?\d*(\.\d{0,2})?$"
                                                    id='tax_percent_txt'
                                                    name='tax_percent'
                                                    value={this.state.company.tax_percent || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'percent')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field className="advance-quick-search" >
                                                <label>Tax Procedure Code</label>
                                                <AdvancedControl
                                                    id={'tax_procedure_code_searcch'}
                                                    gridConfig={this.state.tax_procedure_search_data}
                                                    onGridRowSelection={this.on_tax_procedure_code_grid_row_selection}
                                                    controlId={global_constants.constants.advanced_control_type.tax_procedure_code}
                                                    onSearch={this.on_procedure_code_search}
                                                    headerIdForGridTabNavigation={'tax_procedure_code_searcch'}
                                                    disabled_element={false}
                                                />
                                                <AutoSearchComponent
                                                    default_value={this.state.tax_procedure_code_format}
                                                    prepareRenderList={this.renderResult}
                                                    getList={this.get_procedure_code_quick_search_data_list}
                                                    prepareDataList={this.prepare_suggestion_procedure}
                                                    selectresult={this.on_row_selection_advance_search_tax_procedure_code}
                                                    errorMessage={'No Tax Procedure Code Found !'}
                                                    control_id={global_constants.constants.advanced_control_type.procedure_code}
                                                    is_focus={false}
                                                    is_disabled={false}
                                                    show_clear_search={true}
                                                    clear_search={this.clear_quick_search_tax_procedure_code}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Excise Tax Percentage</label>
                                                <Input fluid id='excise_tax_percentage_txt'
                                                    pattern="^-?\d*(\.\d{0,4})?$"
                                                    name='excise_tax_percentage'
                                                    value={this.state.company.excise_tax_percentage || ''}
                                                    onChange={(e, value) => this.on_company_form_change(e, value, 'decimal_four_digit')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field className="advance-quick-search" >
                                                <label>Excise Tax Procedure</label>
                                                <AdvancedControl
                                                    id={'excise_procedure_code_searcch'}
                                                    gridConfig={this.state.excise_procedure_search_data}
                                                    onGridRowSelection={this.on_excise_procedure_code_grid_row_selection}
                                                    controlId={global_constants.constants.advanced_control_type.excise_procedure_code}
                                                    onSearch={this.on_excise_procedure_code_search}
                                                    search_type={global_constants.constants.advanced_control_type.excise_procedure_code}
                                                    headerIdForGridTabNavigation={'excise_procedure_code_searcch'}
                                                    disabled_element={false}
                                                />
                                                <AutoSearchComponent
                                                    default_value={this.state.excise_procedure_code_format}
                                                    prepareRenderList={this.renderResult}
                                                    getList={this.get_procedure_code_quick_search_data_list}
                                                    prepareDataList={this.prepare_suggestion_procedure}
                                                    selectresult={this.on_row_selection_advance_search_excise_procedure_code}
                                                    errorMessage={'No Excise Tax Procedure Found !'}
                                                    control_id={global_constants.constants.advanced_control_type.procedure_code}
                                                    is_disabled={false}
                                                    is_focus={false}
                                                    show_clear_search={true}
                                                    clear_search={this.clear_quick_search_excise_procedure_code}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            </Grid>
                            <br />
                            {
                                (this.current_user_type != global_constants.enum_user_type_r3_id.Partner
                                    && this.current_user_type != global_constants.enum_user_type_r3_id.Customer) ?
                                    <React.Fragment>
                                        <Grid className="form patient-search-form comapny_details_sub_section mar-t-5 ">
                                            <Grid.Column id="invoice_information_section" computer={16} className="">
                                                <Header as='h2' textAlign="left" className="section-heading-mini_gray" content="Invoice Information (Internal)" />
                                                <Grid>
                                                    <Grid.Column tablet={16} computer={16}>
                                                        <Form.Field >
                                                            <Checkbox className=""
                                                                id="same_as_company_chb" label='Same as Company'
                                                                name='is_same_as_company'
                                                                checked={this.state.company.is_same_as_company || false}
                                                                onChange={(e, value) => this.copy_data(e, true)}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <label>Invoice Address</label>
                                                            <Input fluid
                                                                id='invoice_address1_txt'
                                                                name='inv_address'
                                                                maxLength={50}
                                                                value={this.state.company.inv_address || ''}
                                                                onChange={(e, value) => this.on_company_form_change(e, value, 'txt')}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <label>Invoice Address 2</label>
                                                            <Input fluid id='invoice_address2_txt'
                                                                name='inv_address2'
                                                                maxLength={50}
                                                                value={this.state.company.inv_address2 || ''}
                                                                onChange={(e, value) => this.on_company_form_change(e, value, 'txt')}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <label>Invoice City</label>
                                                            <Input fluid id='invoice_city_txt'
                                                                name='inv_city'
                                                                maxLength={50}
                                                                value={this.state.company.inv_city || ''}
                                                                onChange={(e, value) => this.on_company_form_change(e, value, 'txt')}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <label>Invoice State</label>
                                                            <Selection
                                                                placeHolder='Select'
                                                                id="invoice_state_ddl"
                                                                options={this.state.state_data}
                                                                name='inv_state'
                                                                defaultValue={this.state.company.inv_state || ''}
                                                                onChange={this.on_company_form_change_for_txt}
                                                                disabled={false}
                                                                hidden={true}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <label>Invoice Zip</label>
                                                            <Input fluid id='invoice_zip_txt'
                                                                autoComplete='new-password'
                                                                maxLength={10}
                                                                pattern='[0-9]*'
                                                                name='inv_zip'
                                                                value={zip_code_formation(this.state.company.unformat_inv_zip || '') || ''}
                                                                onChange={(e, value) => this.on_company_form_change(e, value, 'zip')}
                                                                error={this.state.company_form_invalid.inv_zip && this.state.form_submited}
                                                            />
                                                            <p className={this.state.company_form_invalid.inv_zip && this.state.form_submited ? "required-place-bottom req-alert" : 'required-place-bottom'}><i>{this.state.company_form_invalid.inv_zip && this.state.form_submited
                                                                && this.state.company.unformat_inv_zip && this.state.company.unformat_inv_zip != null && this.state.company.unformat_inv_zip.length > 0
                                                                && (this.state.company.unformat_inv_zip.length != 5 || this.state.company.unformat_inv_zip.length != 9)
                                                                ? global_constants.constants.zip_compulsory : ''}
                                                            </i></p>
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <label>Invoice Billing Percent</label>
                                                            <Input fluid id='invoice_billing_percent_txt'
                                                                name='inv_billing_percent'
                                                                pattern="^-?\d*(\.\d{0,4})?$"

                                                                value={this.state.company.inv_billing_percent || ''}
                                                                onChange={(e, value) => this.on_company_form_change(e, value, 'decimal_four_digit')}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <label>Intacct ID</label>
                                                            <Input fluid id='intracct_id_txt'
                                                                maxLength={20}
                                                                name='intacct_id'
                                                                value={this.state.company.intacct_id || ''}
                                                                onChange={(e, value) => this.on_company_form_change(e, value, 'txt')}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field >
                                                            <label>&nbsp;</label>
                                                            <Checkbox
                                                                id="is_webpt_revenue_chb" label='Calculate PIOF Payment Code as WebPT Revenue'
                                                                name='is_webpt_revenue'
                                                                checked={this.state.company.is_webpt_revenue || false}
                                                                onChange={(e, value) => this.on_company_form_change(e, value, 'chb')}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid>
                                            </Grid.Column>
                                        </Grid>
                                        <br />
                                    </React.Fragment>
                                    : null
                            }
                            <IPPermissionComponent title="IP Access"
                                onRef={this.setup_ip_reference}
                                ip_list={this.state.company.ip_address}
                                form_submited={this.state.form_submited}
                                get_parent_ip_list={this.state.update_child_ip_list} />
                            <br />
                            <Grid className="form patient-search-form comapny_details_sub_section mar-t-5 ">
                                <Grid.Column id="logo_section" computer={16} className="">
                                    <Header as='h2' textAlign="left" className="section-heading-mini_gray" content="Logo" />
                                    <Grid>
                                        <Grid.Column tablet={16} computer={16}>
                                            <div style={{ display: 'inline-block', textAlign: 'center' }}>
                                                <Button type='button' id='remove_logo_btn' onClick={() => this.remove_logo()}
                                                    disabled={this.image_preview ? false : true} basic>
                                                    Remove Logo
                                                </Button>
                                                <Button type='button' id='upload_logo_btn'
                                                    onClick={this.upload_logo} basic>
                                                    Upload Logo
                                                </Button>
                                                <input
                                                    type='file'
                                                    ref='input_reader'
                                                    accept='image/png,image/gif,image/jpeg,image/jpg'
                                                    style={{ display: 'none' }}
                                                    value=''
                                                    onChange={e => this.file_handler(e)}
                                                    name='image_base64'
                                                    id='image_base64'
                                                />
                                            </div>
                                        </Grid.Column>
                                        {this.image_preview &&
                                            <Grid.Column tablet={16} computer={16}>
                                                <img id="image_logo_preview"
                                                    src={`${this.image_formate},${this.image_preview}`}
                                                    alt='company logo'
                                                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                                                />
                                            </Grid.Column>
                                        }
                                    </Grid>
                                </Grid.Column>
                            </Grid>
                        </Form>

                    </div>

                    <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area" id="applicationFooterSticky">
                        <Button id="charge_cancel_btn" type='button' basic onClick={this.cancel_add_update} >Cancel</Button>
                        <Button id="company_save_btn" type='button' onClick={() => this.on_save_click(false)} disabled={this.state.loading}
                            onKeyDown={set_focus_to_app_header} primary >Save</Button>
                    </div>
                </div>
                {this.state.show_use_formatter_confirm_popup ?
                    <ConfirmationComponent message={company_messages.show_use_formator_confirm_message}
                        title='Confirm' show_modal={this.state.show_use_formatter_confirm_popup}
                        onCloseModal={this.on_close_use_formatter_confirm_popup}
                        save_button_text='OK'
                        on_force_save_click={(e) => { this.on_save_click(true) }} />
                    : ''}
            </React.Fragment >
        );
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            refresh_token: refresh_token,
        },
        dispatch
    );
};

const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        ff_sumaj_b32093_allow_override_format_for_demand_claims: get_launch_darkly_key_value(state.launch_darkly, AllowOverrideFormatForDemandClaims.sumajB32093AllowOverrideFormatForDemandClaims),
        ff_argos_b33306_remove_access_to_r4_with_overrides: get_launch_darkly_key_value(state.launch_darkly, restrictAccessToR4AtCompanyLevel.argosB33306RemoveAccessToR4WithOverrides),
        ff_argos_bill1579_usc_ot_update: get_launch_darkly_key_value(state.launch_darkly, featureFlags.argosbill1579uscotupdate),
        ff_leviathan_bill_2454_clearinghouse_account_id: get_launch_darkly_key_value(state.launch_darkly, featureFlags.leviathan_bill_2454_clearinghouse_account_id),
        goliath_bill1938_online_payment_support: get_launch_darkly_key_value(state.launch_darkly, OnlinePaymentSupportFlag.goliath_bill1938_online_payment_support)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyComponent));


