export const kraken_claim_scrubbing_visibility_ff_key = 'krakenBill168ClaimsScrubbingVisibility'
export const kraken_dynamic_form_letters_ff_key = 'krakenB34873DynamicFormLetters'
export const rsi_bill_393_ui_billing_failed_charges =
    'rsiBill393UiBillingFailedChargesReportAddNewFieldInSearchCriteria';
export const rsi_bill_456_add_export_feature_on_batch_claims =
    'rsiBill456AddExportFeatureOnBatchClaims';    
export const goliath_835_error_exclusions = 'goliathBill353835ValidationConfiguration';
export const leviathan_streamline_claim_holds = 'leviathanBill542StreamlineClaimHoldsSummary';
export const leviathan_bill_3434_location_credential_claim_holds_r6 = 'leviathanBill3434LocationCredentialClaimHoldsR6';
export const kraken_provider_claim_holds_card_to_stand_alone_page = 'krakenBill812ProviderClaimHoldsCardToStandAlonePage';
export const prime_additional_configurability_for_claims = 'primeB32927AdditionalConfigurabilityForClaims';
export const prime_reactivate_charges = 'primeBill616ReactivateCharges';
export const sumaj_bill_636_enhanced_payer_rules = 'sumajBill636EnhancedPayerRules';
export const argos_bill_1162_editable_835s = 'argosBill1162Editable835S';
export const argos_bill_1627_expected_rates_improvements = 'argosBill1627ExpectedRatesImprovements';
export const kraken_bill_333_remove_and_replace_medicare_crossover_on_payers_page = 'krakenBill333RemoveAndReplaceMedicareCrossoverOnPayersPage'
export const argos_bill_2473_upload_835s = 'argosBill2473Upload835S'
export const e835_partial_patient_adjustment = 'krakenBill2171E835PartialPatientAdjustment'
export const magnus_plat_5681_isolate_auth0 = 'magnusPlat5681IsolateAuth0';
export const claim_adjustment_reason_code_company_overrides = 'krakenBill2252ClaimAdjustmentReasonCodeOverrides';
export const kraken_bill_3155_updates_to_provider_summary_report = 'krakenBill3155UpdatesToProviderSummaryReport';
export const leviathan_bill_3690_company_type_webpt_new_patient_header = 'leviathanBill3690CompanyTypeWebptNewPatientHeader';
